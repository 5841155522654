import React from 'react';
import Home from './Home';
import {   
  //BrowserRouter as Router,
  Switch,
  Route } from "react-router-dom";

// export default function App() {
const App = () => {

  // let { path, url } = useRouteMatch();

    return (
      <div>
        <Switch>
            <Route exact={true} path="/" 
            // component={Home} 
            //{path} //"/" 
            render={(props) => <Home {...props}/>} 
            // render={(props) => <Home {...props}/>}
             />
            <Route 
            path=":hi" 
            // component={Home}
            render={(props) => <Home {...props}/>} 
            />
        </Switch>
      </div>
    );
}

export default App;