import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
// import history from './History';

// function usePageViews() {
//   let location = useLocation();
//   React.useEffect(() => {
//     ga.send(["pageview", location.pathname]);
//   }, [location]);
// }

// export default function Routing() {
//   // usePageViews();
//   return (
//     <Router>  
//       <Switch>
//         <Route
//           exact path="/" 
//           component={App} 
//         />
//         <Route
//           path="/:id" 
//           component={App}  
//         />
//       </Switch>
//     </Router>
//   )
// }

ReactDOM.render(
  <Router>
    <App />
  </Router>, 
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
