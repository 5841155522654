import React from 'react';
import '../src/competences.css';
import logo from '../src/nordlink logo blue transparent.png'



const Competences = (props) => {

    // const programGallerySlideUp = props.gallery === "category" && props.videoChosen === false && props.searchText === "" && props.programImagesLoaded

    return (
      <div className={`competences-container 
        ${props.galleryContainerShow ? "xxx" : "xx"}
      `}>
        <div className={`competences
            ${props.galleryContainerShow ? "xxx" : "xx"}
        `}>
          <img draggable={false} className={`competences logo`} src={logo}></img>
            {/* <span>Services</span> */}
        </div>

      </div>
    )
  }


export default Competences;