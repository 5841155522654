import produce from "immer"

export const setTopOfPage = (topOfPage, galleryOverlap) =>  // galleryOverlap, galleryOverlapMore
    produce(draftState => {
        draftState.topOfPage = topOfPage
        draftState.galleryOverlap = galleryOverlap
        // draftState.galleryOverlapMore = galleryOverlapMore
})

export const setBillBoardIndex = (billBoardIndex) => 
    produce(draftState => {
        draftState.billBoardIndex = billBoardIndex
})

export const setCurrentBillBoardIndex = (selectedIndex) => 
    produce(draftState => {
        draftState.currentBillBoardIndex = selectedIndex
})

export const setBillBoardPlay = () => 
    produce(draftState => {
        draftState.carouselShow = false
        draftState.billBoardPlay = true
        draftState.galleryOverlap = false
        draftState.topOfPage = false
})

export const setBillBoardVideoStarted = (billBoardVideoStarted) => 
    produce(draftState => {
        draftState.billBoardVideoStarted = billBoardVideoStarted
})


export const setMainGalleryElementEntered = (mainGalleryElementHoverId, galleryOverlapMore, enteredCategory) => 
    produce(draftState => {
        draftState.mainGalleryElementHoverId = mainGalleryElementHoverId
        draftState.galleryOverlapMore = galleryOverlapMore
        draftState.programQueryText = enteredCategory
})

export const setMainGalleryElementSelected = (chosenCategory) =>  // (mainGalleryLastElementInRow, mainGalleryELementSelectedId, programQueryText) => //DeleteAAA
    produce(draftState => {
        //draftState.mainGalleryLastElementInRow = mainGalleryLastElementInRow //DeleteAAA
        //draftState.mainGalleryELementSelectedId = mainGalleryELementSelectedId //DeleteAAA
        //Object.keys(draftState.categoryGalleryData).forEach(icon => { //DeleteAAA
            //draftState.categoryGalleryData[icon].focus = false
                                /////// draft.postData[draft.postData.findIndex(x => String(x.itemId) === id)].style = 
                                /////// draft.postData[draft.postData.findIndex(x => String(x.itemId) === id)].style === '0px 0px 0px 0px rgba(255,255,255,1)' ? '10px -10px 0px 0px rgba(255,255,255,1)' : '0px 0px 0px 0px rgba(255,255,255,1)'
        //})
        //draftState.categoryGalleryData[mainGalleryELementSelectedId - 1].focus = true //DeleteAAA
        draftState.programQueryText = chosenCategory
        draftState.currentCategory = chosenCategory
        draftState.gallery = "category"
        draftState.carouselShow = false
        draftState.billBoardPlay = true
        draftState.galleryOverlap = false //reset
        draftState.galleryOverlapMore = false //reset
      
    })
export const setMainGalleryElementLeave = () => 
    produce(draftState => {
        draftState.mainGalleryElementHoverId = null
        draftState.galleryOverlapMore = false
        // draftState.mainGalleryLastElementInRow = null
})


export const setHoverElement = (programCollectionId, activeVideoUrl, activeVideoUrlIndex, hoverFirstElementInRow, videoStartedId) => 
    produce(draftState => {
        draftState.hoverFirstElementInRow = hoverFirstElementInRow
        draftState.activeVideoUrl = activeVideoUrl
        draftState.activeVideoUrlIndex = activeVideoUrlIndex
        draftState.programCollectionId = programCollectionId
        // draftState.activeTitle = activeTitle
        // draftState.activeSeason = activeSeason
        // draftState.activeEpisode = activeEpisode
        // draftState.activeEpisodeTitle = activeEpisodeTitle
        // draftState.activeCategory = activeCategory
        draftState.videoStartedId = videoStartedId

})

export const setHoverElementTagline = (activeTaglineIndex) => 
    produce(draftState => {
        draftState.activeTaglineIndex = activeTaglineIndex
})


export const setVideoChosen = (programCollectionId, activeTitle, activeSeason, activeEpisode, activeEpisodeTitle, activeCategory, activeProgramDescriptionLong, activeEpisodeDescription, activeNumberOfSeasons, activeVideoUrl, activeVideoUrlIndex) =>  //chosenElementId  //programCollectionId, chosenElementId, activeTitle, activeSeason, activeEpisode, activeEpisodeTitle, activeCategory, activeEpisodeDescription, activeProgramDescription, activeNumberOfSeasons
    produce(draftState => {
        draftState.videoChosen = true
        // draftState.programImagesLoaded = false //include in reset
        // draftState.programImageLoadCounter = 0 //include in reset
        draftState.elementHoverOn = false
        // draftState.chosenElementId = chosenElementId
        draftState.programCollectionId = programCollectionId
        draftState.activeVideoUrl = activeVideoUrl
        draftState.activeVideoUrlIndex = activeVideoUrlIndex
        draftState.activeTitle = activeTitle
        draftState.activeSeason = activeSeason
        draftState.activeEpisode = activeEpisode
        draftState.activeEpisodeTitle = activeEpisodeTitle
        draftState.activeEpisodeDescription = activeEpisodeDescription
        draftState.activeProgramDescriptionLong = activeProgramDescriptionLong
        draftState.activeCategory = activeCategory
        draftState.activeNumberOfSeasons = activeNumberOfSeasons

        draftState.gallery = "category" //default setting

        //draftState.programImageLoadCounter = 0 //default setting - verify
        //draftState.programImagesLoaded = false //default setting - verify
})

export const setVideoStarted = (videoStartedId) => 
    produce(draftState => {
        draftState.videoStartedId = videoStartedId
})

export const setSeasonChosen = (seasonChosen) => 
    produce(draftState => {
        draftState.seasonChosen = seasonChosen
})

export const setVideoSelectorEntered = (entered) => 
    produce(draftState => {
        draftState.videoSelectorEntered = entered
})

export const setVideoEpisodeChosen = (season, episode, videoUrl, episodeTitle, episodeDescription) =>  //chosenElementId
    produce(draftState => {
        draftState.activeVideoUrl = videoUrl //videos[chosenElementId].videoUrl = videoUrl
        draftState.videoStartedId = null

        // draftState.activeTitle = activeTitle
        draftState.activeSeason = season
        draftState.activeEpisode = episode
        draftState.activeEpisodeTitle = episodeTitle
        draftState.activeEpisodeDescription = episodeDescription
        // draftState.activeCategory = activeCategory
        // draftState.videoUrl = videoUrl
})

export const setSeasonHovered = (seasonHovered) => 
    produce(draftState => {
        draftState.seasonHovered = seasonHovered
})

export const setEpisodeHovered = (episodeHovered) => 
    produce(draftState => {
        draftState.episodeHovered = episodeHovered
})

// export const setVideoListReady = () => 
//     produce(draftState => {
//         draftState.videoListReady = true
// })

export const setVideolist = (data) => 
    produce(draftState => {
        draftState.videoCollection = [...data] //unshift(...data)
})

export const setPrograms = (data) => 
    produce(draftState => {
        draftState.programs = [...data]
})

export const setSearchActive = (searchActive, gallery) =>
    produce(draftState => {
        draftState.searchActive = searchActive
        draftState.searchTextPartialMatches = []
        draftState.searchTextScroll = ""
        draftState.searchText = ""
        draftState.gallery = gallery
})

export const setSearchInputChanged = (searchText, searchTextPartialMatches, gallery, searchTextScroll, searchTextScrollActivated, searchInputChangeActivated, programGalleryBaseState, recommenderId) =>
    produce(draftState => {
        draftState.searchText = searchText //reset?
        draftState.searchTextPartialMatches = searchTextPartialMatches
        draftState.gallery = gallery
        draftState.programQueryText = searchText
        draftState.searchTextScrollActivated = searchTextScrollActivated
        draftState.searchInputChangeActivated = searchInputChangeActivated
        draftState.searchTextScroll = searchTextScroll
        draftState.searchActive = true
        draftState.programImageLoadCounter = 0
        draftState.carouselShow = true
        draftState.currentRecommenderId = recommenderId //reset
        
        // draftState.programQueryText = "Comedy"

        // draftState.videoChosen = false //stuff to show underside 1
        // draftState.searchText = ""
        // draftState.activeVideoUrl = null
        // draftState.activeVideoUrlIndex = null

})

export const setSearchInputChangedFromScroll = (searchTextScroll, searchTextScrollActivated, searchInputChangeActivated, searchSuggestionClosestIndex, searchSuggestionShowAll) =>
    produce(draftState => {
        draftState.searchTextScroll = searchTextScroll
        draftState.programQueryText = searchTextScroll
        draftState.searchTextScrollActivated = searchTextScrollActivated
        draftState.searchInputChangeActivated = searchInputChangeActivated
        draftState.searchSuggestionClosestIndex = searchSuggestionClosestIndex
        draftState.searchSuggestionShowAll = searchSuggestionShowAll
        
        // draftState.searchSuggestionPositionStarting = searchSuggestionPositionStarting
})

export const setVideoDescriptionsToogle = (videoDescriptionsShow) =>
    produce(draftState => {
        draftState.videoDescriptionsShow = videoDescriptionsShow
})

export const setAudioState = (audioOff) =>
    produce(draftState => {
        draftState.audioOff = audioOff
})

export const setPip = (pipActivated) =>
    produce(draftState => {
        draftState.pipActivated = pipActivated
})

export const setCategoryCollection = (categoryCollection) =>
    produce(draftState => {
        draftState.categoryCollection = categoryCollection
})


//Routing
export const setGoHome = (baseState) => // not in use pt.
    produce(draftState => {
        draftState = baseState
})





















/////






export const setPostTextChange = (postTextChange) => 
  produce(draft => {
    draft.postText = postTextChange
})

export const setPostTagsChange = (postTagsChange) => 
  produce(draft => {
    draft.postTags = postTagsChange
})

export const setPostDateChange = (postDateChange) => 
  produce(draft => {
    draft.postDate = postDateChange
})

export const setPostData = (postDataArray) => 
  produce(draft => {
    draft.postData = postDataArray
    draft.dataLoaded = true
})

export const setTag = (tag) => 
  produce(draft => {
    draft.currentTag = tag
    draft.imageCounter = 0
})


// export const setRecommenderSelected = (recommenderId) => 
//   produce(draft => {
//     draft.currentRecommenderId = recommenderId
// })



