import React from 'react';
//import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../src/category-gallery.css';
import {   
  //BrowserRouter as Router,
  Link
} from "react-router-dom";

import novo from './images/novo.png';

import {   
  useLocation
} from "react-router";

const CategoryGallery = (props) => {

    let location = useLocation();

    const categoriesGallery = props.categoryGalleryData.map((image, i) => 
        <div className={`category-wrapper
          ${props.galleryOverlap ? "overlap" : "nooverlap"}
          ${props.galleryOverlapMore && "overlap-more"}
          ${image.focus && "grow"}`} 
          key={image.id}
        >
          <div className={`category-container`}>
            <span className={"category-container-span"}>{image.category}</span>
            <div className={`category-background`}
              style={{background: image.backgroundPreviewImg}}
            ></div>
                {/* <Link to={`/${image.category.toLowerCase().replace(/ /g,"_")}`}> */}
            <img className={`category-image
              ${props.mainGalleryElementHoverId === i ? "category-fade-out" : "category-fade-in"}
            `}
                key={image.id}
                src={image.image}
                id={image.id}
                alt={image.category}
                onMouseEnter={(e) => props.onGalleryElementEntered(e)}
                onMouseLeave={(e) => props.onGalleryElementLeave(e)}
                //onClick={(e) => props.onGalleryElementSelected(e.target.alt)} //onClick={(e) => props.onGalleryElementSelected(e)}
                onMouseClick={(e) => props.onGalleryElementEntered(e)}
                onLoad={() => props.onCategoryImageChange()}
                onError={() => props.onCategoryImageChange()}
            ></img>
            <div className={`category-description
               ${props.mainGalleryElementHoverId === i ? "category-fade-in" : "category-fade-out"}
               `}>
                {image.description.map(des =>  
                <span className={`category-description-text`}>{des}</span>
                )
                }
            </div>
               {/* </Link> */}
          </div> 
        </div> 

     
        /* i % props.mainGalleryElementsInRow === 0 not working? */
        /* {i % 5 === 4 ? <div className={`break`}></div> : null} */
        /* {i === props.mainGalleryLastElementInRow - 1 ? <div className={`gallery-sub`}>{subGallery}</div> : null} */
      
      
    )
  
    return (
      <>
          <div className={`gallery category-gallery gallery--centered
          //{props.categoryImagesLoaded && location.pathname.split("/").filter(Boolean).length > 0 && "gallery-grid-show"}
          //{props.categoryImagesLoaded === false && "gallery-grid-hide"}
          //{props.galleryContainerShow ? "show" : "hide"}
          ${props.videoChosen ? "gallery-fullwidth" : "gallery-narrow"}
          ${props.gallery === "categories" && location.pathname.split("/").filter(Boolean).length === 0 && "categories-gallery-show"} //props.categoryImagesLoaded
          ${(props.gallery === "category" || location.pathname.split("/").filter(Boolean).length > 0) && "categories-gallery-hide"} //props.categoryImagesLoaded === false
          `}>
              {categoriesGallery}
          </div>

    
        <div className={"cvoglogo"}>
          {/* <span className={"text-size3"}>Pharma CV: Biotech MSc. | 6 years, Process and Project Engineer, Product Supply | 2 years, Quality Partner, Regulatory Affairs | at Novo Nordisk </span>  */}
          
        </div>
        <br></br>
        <br></br>
      </>
     
    )
}

export default CategoryGallery;

// export default withRouter(CategoryGallery);
