import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Header from './Header';
import Intro from './Intro';
import Competences from './Competences';
import BillBoard from './BillBoard';

import Search from './Search';
import Gallery from './Gallery';
import Footer from './Footer';
import { categoryGalleryData } from './CategoryGalleryData';
import { categoryVideos } from './CategoryVideos';
import { newPrograms } from './NewPrograms';
import '../src/index.css';
import { withRouter } from "react-router-dom";

import { 
  setTopOfPage, 
  setMainGalleryElementSelected, 
  setMainGalleryElementLeave, 
  setBillBoardIndex, 
  //setCurrentBillBoardIndex, 
  setBillBoardPlay, 
  setMainGalleryElementEntered,
  setHoverElement, 
  setVideoChosen, 
  setHoverElementTagline, 
  setVideoStarted, 
  setBillBoardVideoStarted, 
  setSeasonChosen, 
  setVideoEpisodeChosen, 
  setVideoSelectorEntered, 
  setSeasonHovered, 
  setEpisodeHovered, 
  // setVideoListReady,
  setSearchActive,
  setVideoDescriptionsToogle,
  setAudioState,
  setPip,
  setCategoryCollection,
  setSearchInputChanged,
  setSearchInputChangedFromScroll,
  
  //setGoHome,
  // setRecommenderSelected
  } from './stateChanges';

class App extends Component {
  constructor(props) {
    super(props);
    this.myElement = null;
  this.state = {
    //content (static)
  
      categoryGalleryData,
      categoryVideos,
      videoCollection: [],
      programs: newPrograms,
      categoriesList: [],

    //home
      topOfPage: true, //UX

    //header
      mainHeaderShow: true,
      audioOff: true,
      pipActivated: false,

    //billboard
      billBoardShow: true,
      carouselShow: false,
      billBoardPlay: false,
      billBoardVideoStarted: false,
      billBoardIndex: 0,
      currentBillBoardIndex: 0,
    
    //category gallery
      programQueryText: "", //search
      categoryGalleryElementsInRow: 5,
      hoverFirstElementInRow: null,
      categoryImageLoadCounter: 0,
      categoryImagesLoaded: false,

    //gallery
      galleryContainerShow: true,
      gallery: "categories",

    //search
      searchActive: false,
      searchText: "",
      currentCategory: "",
      searchTextScroll: "",
      searchTextScrollActivated: false,
      searchInputChangeActivated: true,
      searchSuggestionClosestIndex: null,
      searchSuggestionShowAll: false,
      searchSuggestionPositionStarting: null,
      searchTextPartialMatches: [],
      categoryCollection: [], //search

    //recommendations
      currentRecommenderId: 0,

    //program gallery
      currentPrograms: [],
      randomProgramActive: false,
      noOfProgramsInRow: 3, //setup
      programImageLoadCounter: 0,
      programImagesLoaded: false,
      galleryOverlap: false,
      galleryOverlapMore: false,
      elementHoverOn: true,

    //active video
      videoChosen: false,
      programCollectionId: "",
      videoDescriptionsShow: false,
      videoStartedId: null,

      activeVideoUrl: null,
      activeVideoUrlIndex: null,
      activeTitle: null,
      activeSeason: null,
      activeEpisode: null,
      activeEpisodeTitle: null,
      activeEpisodeDescription: null,
      activeProgramDescriptionLong: null,
      activeCategory: [],
      activeNumberOfSeasons: 1,
      activeTaglineIndex: null,

    //program

    //video-selector
      seasonChosen: 1,
      seasonHovered: null,
      episodeHovered: null,
      videoSelectorEntered: false,
      videoPreviewLoaded: false,

    //Obsolute?
      // galleryPageShow: true,
      // videoListReady: false,

      // mainGalleryElementsInRow: 5,
      // mainGalleryLastElementInRow: null,
      // mainGalleryELementSelectedId: 1,
      // mainGalleryElementHoverId: null,
    }
  
    const programGalleryBaseStateKeys = [
      "videoChosen",
      "activeVideoUrlIndex",
      "programCollectionId",
      "videoDescriptionsShow",
      "videoStartedId",
      "activeVideoUrl",
      "activeVideoUrlIndex",
      "activeTitle",
      "activeSeason",
      "activeEpisode",
      "activeEpisodeTitle",
      "activeEpisodeDescription",
      "activeProgramDescriptionLong",
      "activeCategory",
      "activeNumberOfSeasons",
      "activeTaglineIndex",
      "elementHoverOn",
      "videoPreviewLoaded"
    ]

    // const categoryLoadedOrSelectedBaseStateKeys = [
    //   "videoChosen",
    //   "searchActive",
    //   "activeVideoUrlIndex",
    //   "programCollectionId",
    //   "videoDescriptionsShow",
    //   "videoStartedId",
    //   "activeVideoUrl",
    //   "activeVideoUrlIndex",
    //   "activeTitle",
    //   "activeSeason",
    //   "activeEpisode",
    //   "activeEpisodeTitle",
    //   "activeEpisodeDescription",
    //   "activeProgramDescriptionLong",
    //   "activeCategory",
    //   "activeNumberOfSeasons",
    //   "activeTaglineIndex",
    //   "elementHoverOn",
    // ] 

    const programChosenBaseStatesKeys = [
      "programImageLoadCounter",
      "programImagesLoaded"
    ] 

    const { programs, videoCollection, categoriesList, categoryCollection, scrollToTop, ...baseStateObject } = this.state
    this.baseState = baseStateObject
    this.programGalleryBaseState =  Object.fromEntries(Object.entries(this.state).filter( ([key]) => programGalleryBaseStateKeys.includes(key)))
    //this.categoryLoadedOrSelectedBaseState =  Object.fromEntries(Object.entries(this.state).filter( ([key]) => categoryLoadedOrSelectedBaseStateKeys.includes(key)))
    this.programChosenBaseStates =  Object.fromEntries(Object.entries(this.state).filter( ([key]) => programChosenBaseStatesKeys.includes(key)))
  }

  componentDidMount() {

    console.log("componentDidMount")

    const pathname = this.props.location.pathname.split("/").filter(Boolean)
    if (pathname.length === 0) {
      this.setState({carouselShow: true});
    }



    // this.getPrograms()
    // this.getVideoCollection()

    // for (const [key, value] of Object.entries(this.state)) {
    //   console.log(`${key}: ${value}`);
    // }

    // const hi = Object.entries(this.state).filter( ([prop, val]) => programGalleryBaseStateKeys.includes(prop)).map(([prop, val]) => this.state[prop])
    // filter(program => program.category.some(category => category.includes(this.props.programQueryText))
    // console.log("hi", hi)
    // console.log("base", this.programGalleryBaseState)

  //   const hi = (obj, fn) =>
  // Object.fromEntries(

  // )

    // const getContentPromise  = new Promise((resolve) => {
    //   this.getVideoCollection()
    //   this.getPrograms()
    //   return resolve() 
    // })

    const categoriesList = this.state.categoryGalleryData.map(categoryData => categoryData.category)
    this.setState({categoriesList: categoriesList})

    const getContentPromises  = Promise.all(
        [
          // this.getVideoCollection(),
          // this.getPrograms()
        ]
      ).then(() => {
          //refracture promise
        return 
      }
    )

    const getContent = () => { 
      getContentPromises
          .then(() => {   
            const pathname = this.props.location.pathname.split("/").filter(Boolean)
            if (pathname.length > 0) {
              const episodeChanged = false
              this.handleRouting(pathname, episodeChanged)
              this.handleUpdateCurrentPrograms()
            }
        })
    }

    getContent()

    //this.scrollToTop()

    setTimeout(
      () => {
        const topOfPage = true //window.pageYOffset === 0 ? true : false
        console.log("setTimeout")
        const galleryOverlap = true
        this.setState(setTopOfPage(topOfPage, galleryOverlap));
      },
      50
  );

    window.onscroll = (() => {
      let topOfPage
      let galleryOverlap = false
      if(window.pageYOffset === 0) {
        topOfPage = true
        galleryOverlap = false
        // let galleryOverlapMore = false
        this.setState(setTopOfPage(topOfPage, galleryOverlap)) //, galleryOverlapMore))
      }
      else if (this.state.topOfPage !== false) {
        topOfPage = false
        galleryOverlap = false
        // let galleryOverlapMore = false
        this.setState(setTopOfPage(topOfPage, galleryOverlap)) // galleryOverlapMore))
      }    
    });

    this.billBoardInterval()
  }

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth"
  });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.programs !== prevState.programs) {
      let categoryCollection = this.state.programs.map(programs => programs.category).reduce((prev, curr) => prev.concat(curr), []).filter((item, i, arr) => arr.indexOf(item) === i)
      this.setState(setCategoryCollection(categoryCollection))
    }

    if (
      this.state.searchText !== prevState.searchText ||
      this.state.currentRecommenderId !== prevState.currentRecommenderId ||
      this.state.currentCategory !== prevState.currentCategory ||
      this.state.searchTextScroll !== prevState.searchTextScroll //scroll not firering
      ) {
      this.handleUpdateCurrentPrograms()
    }

      window.onpopstate = e => {
        console.log("onpopstate")
        if (this.props.location.pathname !== prevProps.location.pathname) { //redundant I geuss, cause pathname is changed with onpopstate
          const pathname = this.props.location.pathname.split("/").filter(Boolean)
          const prevPathname = prevProps.location.pathname.split("/").filter(Boolean)
          const episodeChanged = pathname.length === 2 && prevPathname.length === 2 && true
          // const episodeChanged = this.props.match.params.seasonepisode !== prevProps.match.params.seasonepisode ? true : false
          if (pathname.length === 1) {
            const paramsSearchText = this.props.match.params.category.toLowerCase()
            //const category = pathname[0].toLowerCase().replace(/ /g,"_")
            this.handleProgramImageDomNodeCount(paramsSearchText)
          }
          this.handleRouting(pathname, episodeChanged)

  
          // const program = !this.props.match.params.program ? null : this.props.match.params.program 
          // const seasonepisode = !this.props.match.params.seasonepisode ? null : this.props.match.params.seasonepisode
          // if (seasonepisode !== null ) {
          // const season = seasonepisode.split('-')[0]
          // const episode = seasonepisode.split('-')[1]
          // }
      // haxndleCategoryTagClicked
        }
      
      }
  }

  handlePushHistory = (url) => {
    console.log("handlePushHistory")
    //const urlToPush = encodeURIComponent(url.normalize('NFKD').replace(combining, '').toLowerCase().replace(/ /g,"_"))
    const urlToPush = encodeURI(url.toLowerCase().replace(/ /g,"_"))
    //const urlToPush = url.toLowerCase().replace(/ /g,"_")
    this.props.history.push(urlToPush);
  }

  handleRouting = (pathname, episodeChanged) => {

    let seasonepisode = ""
    let programTitle = ""
    let season = ""
    let episode = ""
    let programCollectionId = ""
    let routeOk = true

    try {
      if (pathname.length === 2) {
        seasonepisode = this.props.match.params.seasonepisode
        programTitle = pathname.length === 2 ? this.props.match.params.program : null
        season = pathname.length === 2 ? Number(seasonepisode.split('-')[0]) : null
        episode =  pathname.length === 2 ?  Number(seasonepisode.split('-')[1]) : null
        programCollectionId = this.state.programs.find(program => program.title.toLowerCase().replace(/ /g,"_") === programTitle).programCollectionId
        //programCollectionId = pathname.length === 2 && season.isInteger() && episode.isInteger() ? this.state.programs.find(program => program.title.toLowerCase().replace(/ /g,"_") === programTitle).programCollectionId : null
      }
    }
    catch(err) {
      routeOk = false
    }

    switch (true) {
      case pathname.length === 0 || !routeOk:
        this.handleGoHome()
        if (!routeOk) {
          const url = ""
          this.handlePushHistory(url) //this.props.history.push("/")
        } 
        break;
      case pathname.length === 1:
        const paramsQueryText = this.props.match.params.category.toLowerCase().replace(/_/g," ") //substring(0, 1).toUpperCase() + this.props.match.params.category.substring(1).toLowerCase()
        // if (this.state.categoryGalleryData.filter(categoryGallery => categoryGallery.category === searchText).length > 0 && this.state.searchText === "") {
        if (this.state.categoriesList.filter(category => category.toLowerCase() === paramsQueryText).length > 0 && this.state.searchText === "") {
          const paramsCategory = paramsQueryText
          this.handleCategoryLoadedOrSelected(paramsCategory)
        } 
        else if (paramsQueryText.includes("anbefalet af")) {
          const recommenderFirstName = paramsQueryText.replace("anbefalet af","").split(" ")[1];
          const recommenderLastName = paramsQueryText.replace("anbefalet af","").split(" ")[2];
          const recommenderId = this.state.recommendationsAuthorsList.filter(recommender => recommender.firstName.toLowerCase()+recommender.lastName.toLowerCase() === recommenderFirstName+recommenderLastName).map(recommender => recommender.individualId)[0]
          this.handleLoadNonCategoryOrClikedRecommender(paramsQueryText, recommenderId)
        }
        else {
          const recommenderId = 0
          this.handleLoadNonCategoryOrClikedRecommender(paramsQueryText, recommenderId)
        }
        break;
      case pathname.length === 2 && episodeChanged === false:
        const activeVideoUrlIndex = 0 // random number, should be changed?
        this.handleProgramLoadedOrSelected(programCollectionId, season, episode, activeVideoUrlIndex)
        break; 
      case pathname.length === 2 && episodeChanged:
        this.handleVideoEpisodeChosen(programCollectionId, season, episode)
        break; 
      default:
    } 
  }
  
  componentWillUnmount() {
    window.onscroll = null;
  }

  handleGoHome = () => {
    const url = "/"
    console.log("handleGoHome")
    const baseState = Object.assign(this.baseState, {carouselShow: true})
    // console.log(baseState)
    this.handlePushHistory(url)
    this.setState(baseState) //this.setState(setGoHome(baseState)) - fix to work;
  }

  handleUpdateCurrentPrograms = () => {
    let currentPrograms = this.state.currentPrograms
    const programs = this.state.programs
    const searchText = this.state.searchTextScroll === "" ? this.state.searchText : this.state.searchTextScroll
    const matchAllPrograms = programs.filter(program => program.category.some(category => category.toLowerCase().includes(searchText))) //
    const matchAllProgramsInCategory = this.state.searchText === "" ? 
      programs.filter(program => program.category.some(category => category.toLowerCase() === this.state.currentCategory)) : 
      programs.filter(program => program.category.some(category => category.toLowerCase() === this.state.currentCategory)).filter(program => program.category.some(category => category.toLowerCase().includes(searchText)))
    let randomProgramActive = false

    if (programs.length > 0) {
      switch (true) {
        case this.state.currentCategory === "" && this.state.currentRecommenderId === 0 && matchAllPrograms.length > 0:
          //const matchAllPrograms =  programs.filter(program => program.category.some(category => category.toLowerCase().includes(this.state.programQueryText)))
          currentPrograms = matchAllPrograms
        break;
        case this.state.currentCategory !== "" && this.state.currentRecommenderId === 0 && matchAllProgramsInCategory.length > 0:
          // const matchAllProgramsInCategory = this.state.searchText === "" ? 
          //   programs.filter(program => program.category.some(category => category.toLowerCase() === this.state.currentCategory)) : 
          //   programs.filter(program => program.category.some(category => category.toLowerCase() === this.state.currentCategory)).filter(program => program.category.some(category => category.toLowerCase().includes(this.state.searchText)))
          currentPrograms = matchAllProgramsInCategory
        break;
        case this.state.currentRecommenderId !== 0:
          const recommendedProgramCollectionIds = this.state.currentRecommenderId !== 0 ? this.state.recommendationsList.filter(recommendation => recommendation.individualId === this.state.currentRecommenderId).map(recommendation => recommendation.programCollectionId) : 0
          const recommendedPrograms = programs.filter(program => recommendedProgramCollectionIds.includes(program.programCollectionId)) 
          currentPrograms = recommendedPrograms
        break;
        case matchAllPrograms.length === 0 && matchAllProgramsInCategory.length === 0 && this.state.currentRecommenderId === 0: // if matchAllPrograms and matchAllProgramsInCategory lenght is zero. Consider use af default or explicit case
          const programCollectionIds = programs.map(program => program.programCollectionId)
          const randomProgramCollectionId = programCollectionIds[Math.floor(Math.random() * programCollectionIds.length)];
          const randomProgramSuggestion = programs.filter(program => program.programCollectionId === randomProgramCollectionId)
          currentPrograms = randomProgramSuggestion
          randomProgramActive = true
        break; 
        default:
      } 
    }

    if (searchText !== "" || this.state.currentCategory !== "") {
      currentPrograms.sort((a, b) => {
        const sortSearchText = searchText !== "" ? searchText : this.state.currentCategory
        const sortSearchTextCheck = (element) => element.toLowerCase() === sortSearchText;
        const sortIndexA = a.category.findIndex(sortSearchTextCheck)
        const sortIndexB = b.category.findIndex(sortSearchTextCheck)
        return parseInt(a.sortId[sortIndexA]) - parseInt(b.sortId[sortIndexB])
        ;
      })
    } else {
      currentPrograms.sort((a, b) => parseInt(a.sortId[0]) - parseInt(b.sortId[0]))
    }
    this.setState({currentPrograms: currentPrograms, randomProgramActive: randomProgramActive})
  }

  // handleBillBoardInterval = () => {
  //   let billBoardIndex = this.state.billBoardIndex !== this.state.billBoards.length - 1? this.state.billBoardIndex + 1 : 0
  //   this.setState(setBillBoardIndex(billBoardIndex));
  // }

  billBoardInterval = () => {
    //this.billBoardIntervall = setInterval(() => this.handleBillBoardInterval(), 5000);
  }

  handleBillBoardChange = (selectedIndex) => {
    let billBoardIndex = selectedIndex
    selectedIndex !== this.state.billBoardIndex && this.handleBillBoardChosen(billBoardIndex)
  }

  handleBillBoardChosen = (billBoardIndex) => {
    clearInterval(this.billBoardIntervall)
    this.billBoardInterval()
    this.setState(setBillBoardIndex(billBoardIndex))
  }

  handleGalleryElementLeave = (e) => {
    this.setState(setMainGalleryElementLeave())
    // this.setState(setMainGalleryElementLeave())
  }

  handleGalleryElementEntered = (e) => {
    const mainGalleryElementHoverId = e.target.id - 1
    const galleryOverlapMore = this.state.topOfPage ? true : false
    // const enteredCategory = this.state.categoryGalleryData[mainGalleryElementHoverId].category //fix: causes view to show wrongly. Purpose is to preload a few seconds before
    this.setState(setMainGalleryElementEntered(mainGalleryElementHoverId, galleryOverlapMore)) //enteredCategory
  }

  handleVideoStart = (e, i) => {
    clearInterval(this.billBoardIntervall);
    let videoStartedId = i
    this.setState(setVideoStarted(videoStartedId))
  }

  handleBillBoardVideoStart = () => {
    let billBoardVideoStarted = true
    this.setState(setBillBoardVideoStarted(billBoardVideoStarted))
    this.handleVideoStart(null, null)
  }

  handleBillBoardPlay = () => {
    clearInterval(this.billBoardIntervall);
    this.setState(setBillBoardPlay())
  }

  handleElementHover = (e, i, programCollectionId) => { 

    // console.log(this.state.videoCollection.find(program => program.programCollectionId === programCollectionId && program.season === 1 && program.episode === 1).videoUrl)
    const categoryGalleryElementsInRow = this.state.categoryGalleryElementsInRow
    const activity = e.type === "mouseleave" ? "out" : "over"
    const hoverFirstElementInRow = activity === "over" ? (Math.ceil((i+1)/categoryGalleryElementsInRow)*categoryGalleryElementsInRow) : null
    // const activeVideoUrl = activity === "over" ? e.target.getAttribute("videourl") : null
    const activeVideoUrl = activity === "over" ? 
      this.state.videoCollection.find(program => program.programCollectionId === programCollectionId && program.season === 1 && program.episode === 1).videoUrl 
      : null
    const activeVideoUrlIndex = activity === "over" ? i : null
    // const activeTitle = activity === "over" ? e.target.getAttribute("title") : null
    // const activeSeason = activity === "over" ? e.target.getAttribute("season") : null
    // const activeEpisode = activity === "over" ? e.target.getAttribute("episode") : null
    // const activeCategory = activity === "over" ? e.target.getAttribute("category") : null
    // const activeEpisodeTitle = activity === "over" ? e.target.getAttribute("episodetitle") : null
    const videoStartedId = activity === "out" ? null : null
    this.setState(setHoverElement(programCollectionId, activeVideoUrl, activeVideoUrlIndex, hoverFirstElementInRow, videoStartedId))
  }

  handleElementTaglineHover = (e, i) => {
    
    const activity = e.type === "mouseleave" ? "out" : "over" 
    const activeTaglineIndex = activity === "over" ? 
      (this.state.activeTaglineIndex === i ? null : i) : 
      null 
    this.setState(setHoverElementTagline(activeTaglineIndex))
  }

  handleProgramLoadedOrSelected = (programCollectionId, season, episode, videoUrlIndex) => { //} (e, i, program) => { //CollectionId, title, season, episode, episodeTitle, category, episodeDescription, programDescription
    // let chosenElementId = i
    //const programCollectionId = e.target.key// program.programCollectionId //already set on hover
    const videoCollection = this.state.videoCollection
    const programs = this.state.programs
    const activeCategory = programs.find(program => program.programCollectionId === programCollectionId).category //program.category
    const activeTitle = programs.find(program => program.programCollectionId === programCollectionId).title//program.title
    const activeProgramDescriptionLong = programs.find(program => program.programCollectionId === programCollectionId).programDescriptionLong //program.programDescriptionLong
    const activeSeason = season
    const activeEpisode = episode
    const activeEpisodeTitle = videoCollection.find(program => program.programCollectionId === programCollectionId && program.season === season && program.episode === episode).episodeTitle
    const activeEpisodeDescription = videoCollection.find(program => program.programCollectionId === programCollectionId && program.season === season && program.episode === episode).episodeDescription
    const activeVideoUrl = this.state.activeVideoUrl === null ? videoCollection.find(program => program.programCollectionId === programCollectionId && program.season === 1 && program.episode === 1).videoUrl : this.state.activeVideoUrl
    const activeVideoUrlIndex = this.state.activeVideoUrlIndex === null ? videoUrlIndex : this.state.activeVideoUrlIndex
    let activeNumberOfSeasons = Math.max.apply(Math, videoCollection.filter(video => video.programCollectionId === programCollectionId).map(video => video.season), 1)
    // console.log(activeProgramDescriptionLong, "XXX", activeEpisodeDescription)
    this.setState(setVideoChosen(programCollectionId, activeTitle, activeSeason, activeEpisode, activeEpisodeTitle, activeCategory, activeProgramDescriptionLong, activeEpisodeDescription, activeNumberOfSeasons, activeVideoUrl, activeVideoUrlIndex)) //
    this.scrollToTop()
    // this.handleRouting(programCollectionId)
  }

  // handleRouting = (programCollectionId) => {

  //   // let history = useHistory()
  //   // history.push("/programCollectionId");
  //   // return function (dispatch) {
  //     // return loginRemotely(credentials)
  //     //   .then((response) => {
  //         // ...
  //         // history.push('/programCollectionId');
  //       // });
  //   // };
  // }

  handleSeasonChosen = (seasonData) => {
    let seasonChosen = Number.isInteger(seasonData) ? seasonData : Number(seasonData.currentTarget.dataset.id)
    this.setState(setSeasonChosen(seasonChosen))
    this.videoListScrollToTop()
  }

  handleVideoEpisodeChosen = (programCollectionId, season, episode) => {
    if ((programCollectionId === this.state.programCollectionId && season === this.state.activeSeason && episode === this.state.activeEpisode) === false) {
      const videoCollection = this.state.videoCollection
      // const activeSeason = season
      // const activeEpisode = episode
      const episodeTitle = videoCollection.find(program => program.programCollectionId === programCollectionId && program.season === season && program.episode === episode).episodeTitle
      const episodeDescription = videoCollection.find(program => program.programCollectionId === programCollectionId && program.season === season && program.episode === episode).episodeDescription
      const videoUrl = videoCollection.find(program => program.programCollectionId === programCollectionId && program.season === season && program.episode === episode).videoUrl

              // const activeTitle = video.title
                // const activeCategory = video.category
      // const activeSeason = video.season
      // const activeEpisode = video.episode
      // const activeEpisodeTitle = video.episodeTitle
      // const activeEpisodeDescription = video.episodeDescription
      // let videoUrl = video.videoUrl
              // let chosenElementId = this.state.chosenElementId
      this.setState(this.programChosenBaseStates) 
      this.setState(setVideoEpisodeChosen(season, episode, videoUrl, episodeTitle, episodeDescription)) //chosenElementId
    }
  }

  handleVideoEnded = () => {
    const videoCollection = this.state.videoCollection
    const programCollectionId = this.state.programCollectionId
    const activeSeason = this.state.activeSeason
    const activeEpisode = this.state.activeEpisode
    const nextEpisodeSameSeason = videoCollection.find(program => program.programCollectionId === programCollectionId && program.season === activeSeason && program.episode === activeEpisode + 1)
    const nextEpisodeNextSeason = videoCollection.find(program => program.programCollectionId === programCollectionId && program.season === activeSeason + 1 && program.episode === 1)
    if (typeof(nextEpisodeSameSeason) !== 'undefined' || typeof(nextEpisodeNextSeason) !== 'undefined') {
      const nextEpisode = (typeof(nextEpisodeSameSeason) !== 'undefined') ? nextEpisodeSameSeason.episode : nextEpisodeNextSeason.episode
      const nextSeason = (typeof(nextEpisodeSameSeason) !== 'undefined') ? activeSeason : activeSeason + 1
      const url = `/${this.state.activeTitle.toLowerCase().replace(/ /g,"_")}/${nextEpisode}-${nextSeason}`
      this.handleVideoEpisodeChosen(programCollectionId, nextSeason, nextEpisode)
      this.handlePushHistory(url)
      if (typeof(nextEpisodeSameSeason) === 'undefined') { //nextEpisode === nextEpisodeNextSeason.episode) {
        this.handleSeasonChosen(nextSeason)
        this.videoListScrollToTop()
      }
    }
  }

  videoListScrollToTop = () => {
      this.myElement.scrollTo({
        top: 0,
        behavior: "smooth"
      });
  }

  handleEnterVideoSelector = (e) => {
    const entered = e.type === "mouseleave" ? false : true
    this.setState(setVideoSelectorEntered(entered))
  }

  handleSeasonEnter = (e, i) => {
    let seasonHovered = e.type === "mouseleave" ? null : Number(e.currentTarget.dataset.id)
    this.setState(setSeasonHovered(seasonHovered))
  }

  handleEpisodeEnter = (e, index) => {
    let episodeHovered = e.type === "mouseleave" ? null : index
    this.setState(setEpisodeHovered(episodeHovered))
  }

  // videoListReady = () => {
  //   this.setState(setVideoListReady())
  // }


  handleVideoDescriptionsToogleClicked = () => {
    console.log("hi")
    const videoDescriptionsShow = this.state.videoDescriptionsShow ? false : true
    this.setState(setVideoDescriptionsToogle(videoDescriptionsShow))
  }

  handleAudioSymbolClicked = () => {
    const audioOff = this.state.audioOff ? false : true
    this.setState(setAudioState(audioOff))
  }

  handlePipClicked = () => {
    let pipActivated = this.state.pipActivated ? false : true
    this.setState(setPip(pipActivated))

      // try {
      //   const videoElement = ReactDOM.findDOMNode("html5-main-video") 
      //   if (!document.pictureInPictureElement) {

      //       videoElement.requestPictureInPicture()
      //       console.log("testpip")
      //   } else {
      //       document.exitPictureInPicture();
      //   }
      //   } catch(reason) {
      //       console.error(reason);
      //   }
      
  }

  handleSearchIconClicked = () => {
    const searchActive = this.state.searchActive ? false : true
    let gallery = "category"
    if (this.state.currentCategory === "") {
      gallery = "categories" 
      const url = "/"
      this.handlePushHistory(url)
    }
    // this.handlePushHistory()
    this.setState(setSearchActive(searchActive, gallery))
  }

  handleSearch = (e) => {
    e.preventDefault();
    // console.log('handleSearch')
  }

  handleCategoryLoadedOrSelected = (category) => {
    const chosenCategory = category.toLowerCase()
    //const mainGalleryELementSelectedId = Math.round(e.target.id) //DeleteAAA
    //const mainGalleryLastElementInRow = Math.ceil(e.target.id/this.state.mainGalleryElementsInRow)*this.state.mainGalleryElementsInRow //DeleteAAA
    //const programQueryText = category  // this.state.categoryGalleryData[mainGalleryELementSelectedId-1].category //DeleteAAA
    this.setState(this.programGalleryBaseState) //combine with setSearchInputChanged
    this.setState(setMainGalleryElementSelected(chosenCategory)) //this.setState(setMainGalleryElementSelected(mainGalleryLastElementInRow, mainGalleryELementSelectedId, programQueryText)) //DeleteAAA
    this.scrollToTop()
  }

  handleLoadNonCategoryOrClikedRecommender = (paramsQueryText, recommenderId) => {
    const searchText = paramsQueryText.toLowerCase()
    const searchTextForMatch = new RegExp("\\b" + searchText,"ig") // RegExp("(\\b"+searchText+"\\S+\\b)","ig") 
    const searchTextPartialMatches = this.state.categoryCollection.filter(category => category.toLowerCase().match(searchTextForMatch)).filter(category => category !== searchText).sort()
    const gallery = "category"
    const searchTextScrollActivated = true
    const searchInputChangeActivated = true
    const searchTextScroll = ""
    //const searchText = this.state.searchText === "" ? "" : searchTextPassed
    this.setState(this.programGalleryBaseState) //combine with setSearchInputChanged
    const programGalleryBaseState = this.programGalleryBaseState
    this.setState(setSearchInputChanged(searchText, searchTextPartialMatches, gallery, searchTextScroll, searchTextScrollActivated, searchInputChangeActivated, programGalleryBaseState, recommenderId)) 

  }

  handleSuggestionSelected = (suggestion) => {
    const searchText = suggestion.toLowerCase()
    const searchTextPartialMatches = "" //this.state.categoryCollection.filter(category => category.includes(searchTextPassed))
    const gallery = "category"
    const searchTextScrollActivated = true
    const searchInputChangeActivated = true
    const searchTextScroll = ""
    this.setState(this.programGalleryBaseState) //combine with setSearchInputChanged
    const programGalleryBaseState = this.programGalleryBaseState
    const recommenderId = 0
    this.setState(setSearchInputChanged(searchText, searchTextPartialMatches, gallery, searchTextScroll, searchTextScrollActivated, searchInputChangeActivated, programGalleryBaseState, recommenderId)) 

  }

  handleSearchInputChanged = (e) => {

    if (this.state.searchInputChangeActivated && this.state.searchActive) {
      console.log("handleSearchInputChanged")
      const searchText = e.target.value.toLowerCase() //substring(0, 1).toUpperCase() + e.target.value.substring(1).toLowerCase(); //converts first character in search text to capital letter to prepare for comparison with database
      const searchTextForMatch = new RegExp("\\b" + searchText,"ig")
      const searchSuggestionsWithinAll = this.state.categoryCollection.filter(category => category.toLowerCase().match(searchTextForMatch) && category.toLowerCase() !== searchText).sort()
      const searchSuggestionFromMatchedPrograms = this.state.programs.filter(program => program.category.some(category => category.toLowerCase().includes(this.state.currentCategory))).filter(program => program.category.some(category => category.toLowerCase().match(searchTextForMatch))).map(program => program.category).reduce((prev, curr) => prev.concat(curr), []).filter((item, i, arr) => arr.indexOf(item) === i) //this.state.categoryCollection.filter(category => category.toLowerCase().includes(searchText) && category.toLowerCase().includes(this.state.currentCategory) && category.toLowerCase() !== searchText)
      const searchSuggestionsWithinCategory = searchSuggestionFromMatchedPrograms.sort().filter(category => category.toLowerCase() !== this.state.currentCategory).filter((category) => {
        return searchSuggestionsWithinAll.indexOf(category) !== -1;
      });
      //const searchSuggestionsWithinCategorySorted = searchSuggestionsWithinCategory
      const searchTextPartialMatches = searchText.length > 0 ? 
        (this.state.currentCategory === "" ? 
          // this.state.categoryCollection.filter(category => category.toLowerCase().includes(searchText) && category.toLowerCase() !== searchText) :
          searchSuggestionsWithinAll : searchSuggestionsWithinCategory)
          //this.state.programs.filter(program => program.category.some(category => category.toLowerCase() === this.props.currentCategory)).filter(program => program.category.some(category => category.toLowerCase().includes(searchText)))) 
        : [];
     // const gallery = searchText === "" ? "categories" : "category"
      const gallery = (searchText === "" && this.state.currentCategory === "") ? "categories" : "category"
      const searchTextScrollActivated = true
      const searchInputChangeActivated = true
      const searchTextScroll = ""
      if (this.state.currentCategory === "" && (searchTextPartialMatches.length > 0 || searchText === "")) {
        this.handlePushHistory(searchText)
      } 
      this.setState(this.programGalleryBaseState) //combine with setSearchInputChanged
      const programGalleryBaseState = this.programGalleryBaseState
      const recommenderId = 0
      this.setState(setSearchInputChanged(searchText, searchTextPartialMatches, gallery, searchTextScroll, searchTextScrollActivated, searchInputChangeActivated, programGalleryBaseState, recommenderId))
    }
  }

  handleSearchTextPartialMatchScroll = (e) => {
    if (this.state.searchTextPartialMatches.length > 0 && this.state.searchInputChangeActivated && this.state.searchTextScrollActivated) {
      
      let searchSuggestionPositionArray = []
      const searchSuggestionArray = document.getElementsByClassName('accordionContent')

        for (let index = 0; index < searchSuggestionArray.length; index++) {
          const position = searchSuggestionArray[index].getBoundingClientRect().left * 100 / window.innerWidth 
          searchSuggestionPositionArray.push(position)  
        }

        const goal = 30
        const searchSuggestionClosestIndex =  searchSuggestionPositionArray.indexOf(this.findClosest(goal, searchSuggestionPositionArray))
  
        const searchSuggestionShowAll = searchSuggestionClosestIndex === 0 ? true : false
        let searchTextScroll = searchSuggestionShowAll ? this.state.searchText : this.state.searchTextPartialMatches[searchSuggestionClosestIndex-1].toLowerCase()

        const searchTextScrollActivated = true
        const searchInputChangeActivated = true

        this.setState(setSearchInputChangedFromScroll(searchTextScroll, searchTextScrollActivated, searchInputChangeActivated, searchSuggestionClosestIndex, searchSuggestionShowAll))
    }
  }

findClosest (x, arr) {
  var indexArr = arr.map(function(k) { return Math.abs(k - x) })
  var min = Math.min.apply(Math, indexArr)
  return arr[indexArr.indexOf(min)]
}


  testButton = () => {
    console.log("Test Hi")
  }

  handleProgramImageDomNodeCount = (paramsSearchText) => {
    const imageDomNode = ReactDOM.findDOMNode(this).getElementsByClassName('element-image')
    let noOfPrograms = this.state.programs.filter(program => program.category.some(category => category.toLowerCase().includes(paramsSearchText))).length //(program => program.category.includes(category)).length
    const noOfImageDomNodesCompleted = Array.from(imageDomNode).filter(img => img.complete).length
    console.log("handleProgramImageDomNodeCount", noOfImageDomNodesCompleted, noOfPrograms)
    if (noOfImageDomNodesCompleted === noOfPrograms) {
      this.setState({programImagesLoaded: true, programImageLoadCounter: 0});
      console.log("handleProgramImageDomNodeCount-fulfilled", noOfImageDomNodesCompleted, noOfPrograms)
    }
  }

  handleProgramImageChange = () => {
    let programImageLoadCounter = this.state.programImageLoadCounter + 1
    let noOfPrograms = this.state.programs.filter(program => program.category.some(category => category.toLowerCase().includes(this.state.programQueryText))).length
    this.setState({programImageLoadCounter: programImageLoadCounter});
    console.log("handleProgramImageChange", programImageLoadCounter, noOfPrograms)
    if (noOfPrograms === programImageLoadCounter || programImageLoadCounter > 5) {
      this.setState({programImagesLoaded: true, programImageLoadCounter: 0});
      console.log("handleProgramImageChange-fulfilled", programImageLoadCounter, noOfPrograms)
    }
  }

  handleCategoryImageChange = () => {
    let categoryImageLoadCounter = this.state.categoryImageLoadCounter + 1
    let noOfCategories = this.state.categoryGalleryData.length
    this.setState({categoryImageLoadCounter: categoryImageLoadCounter});
      if (noOfCategories === categoryImageLoadCounter) {
      this.setState({categoryImagesLoaded: true, categoryImageLoadCounter: 0});
      }
    }

  handleVideoPreviewLoaded = () => {
    // console.log("hi")
    const videoPreviewDomNodes = ReactDOM.findDOMNode(this).getElementsByClassName("react-player__preview")
    // console.log(videoPreviewDomNodes)
    let noOfVideoPreview = this.state.videoCollection.filter(video => video.programCollectionId === this.state.programCollectionId && video.season === this.state.seasonChosen).length 
    //let noOfVideoPreview = this.state.videoCollection.filter(video => video.category.some(category => category.toLowerCase().includes(paramsSearchText))).length //(program => program.category.includes(category)).length
    const noOfVideoPreviewDomNodesCompleted = Array.from(videoPreviewDomNodes).filter(node => node.clientHeight !== "").length
    if (noOfVideoPreviewDomNodesCompleted === noOfVideoPreview) {
      // console.log(noOfVideoPreviewDomNodesCompleted, noOfVideoPreview)
      this.setState({videoPreviewLoaded: true});
    }
  }

  handleRecommenderClicked = (recommender) => {
    const recommenderId = recommender.individualId
    const searchText = "anbefalet af " + recommender.firstName + " " + recommender.lastName
    const url = searchText
    //this.setState(setRecommenderSelected(recommenderId))
    this.handlePushHistory(url)
    //const recommendation = true
    this.handleLoadNonCategoryOrClikedRecommender(searchText, recommenderId) //make own or reuse handleLoadNonCategory?
    //const recommendations = recommendationsList.filter(recommendation => recommendation.individualId === recommenderId)
  }


  render() {
    return (
      <div className="app">
      {/* <div className="app-grid"> */}
        {/* <Header
          topOfPage={this.state.topOfPage}
          videoChosen={this.state.videoChosen}
          audioOff={this.state.audioOff}
          pipActivated={this.state.pipActivated}
          onAudioSymbolClicked={this.handleAudioSymbolClicked}
          onPipClicked={this.handlePipClicked}
          onGoHome={this.handleGoHome}
        ></Header> */}
        {/* <button className="buttontest"
                   onClick={() => this.testButton()}
        ></button> */}
        {/* <BillBoard
          billBoardShow={this.state.billBoardShow}
          billBoardIndex={this.state.billBoardIndex}
          currentBillBoardIndex={this.state.currentBillBoardIndex}
          billBoards={this.state.billBoards}
          categoryVideos={this.state.categoryVideos}
          programQueryText={this.state.programQueryText}
          carouselShow={this.state.carouselShow}
          billBoardPlay={this.state.billBoardPlay}
          videoChosen={this.state.videoChosen}
          gallery={this.state.gallery}
          billBoardVideoStarted={this.state.billBoardVideoStarted}
          searchText={this.state.searchText}
          categoryGalleryData={this.state.categoryGalleryData}
          currentCategory={this.state.currentCategory}
          // onMediaEnded={this.handleBillBoardInterval}
          onBillBoardVideoStart={this.handleBillBoardVideoStart}
          onBillBoardChange={this.handleBillBoardChange}
          onPlay={this.handleBillBoardPlay}
        ></BillBoard> */}

        {/* <Search
          gallery={this.state.gallery}
          searchActive={this.state.searchActive}
          searchTextPartialMatches={this.state.searchTextPartialMatches}
          onSearchIconClicked={this.handleSearchIconClicked}
          searchText={this.state.searchText}
          currentCategory={this.state.currentCategory}
          programImagesLoaded={this.state.programImagesLoaded}
          searchTextScroll={this.state.searchTextScroll}
          searchTextScrollActive={this.state.searchTextScrollActive}
          searchSuggestionShowAll={this.state.searchSuggestionShowAll}
          videoChosen={this.state.videoChosen}
          galleryOverlap={this.state.galleryOverlap}
          recommendationsAuthorsList={this.state.recommendationsAuthorsList}
          // searchSuggestionClosestIndex={this.state.searchSuggestionClosestIndex}
          onSearch={this.handleSearch}
          onSearchInputChanged={this.handleSearchInputChanged}
          onCategoryTagClicked={this.handleSuggestionSelected}
          onSearchTextPartialMatchScroll={this.handleSearchTextPartialMatchScroll}
          onRecommenderClicked={this.handleRecommenderClicked}
        ></Search> */}

        {/* <Intro></Intro> */}
        
        <Competences></Competences>

        <Gallery
            galleryContainerShow={this.state.galleryContainerShow}
            categoryGalleryData={this.state.categoryGalleryData}
            mainGalleryItemsInRow={this.state.mainGalleryItemsInRow}
            mainGalleryLastElementInRow={this.state.mainGalleryLastElementInRow}
            mainGalleryELementSelectedId={this.state.mainGalleryELementSelectedid}
            currentCategory={this.state.currentCategory}
            gallery={this.state.gallery}
            programs={this.state.programs}
            videoCollection={this.state.videoCollection}
            galleryOverlap={this.state.galleryOverlap}
            galleryOverlapMore={this.state.galleryOverlapMore}
            mainGalleryElementHoverId={this.state.mainGalleryElementHoverId}
            hoverFirstElementInRow={this.state.hoverFirstElementInRow}
            activeVideoUrl={this.state.activeVideoUrl}
            activeVideoUrlIndex={this.state.activeVideoUrlIndex}
            activeTaglineIndex={this.state.activeTaglineIndex}
            videoChosen={this.state.videoChosen}
            elementHoverOn={this.state.elementHoverOn}
            videoStartedId={this.state.videoStartedId}
            programQueryText={this.state.programQueryText}
            activeTitle={this.state.activeTitle}
            activeSeason={this.state.activeSeason}
            activeEpisode={this.state.activeEpisode}
            activeCategory={this.state.activeCategory}
            activeEpisodeTitle={this.state.activeEpisodeTitle}
            activeEpisodeDescription={this.state.activeEpisodeDescription}
            videoDescriptionsShow={this.state.videoDescriptionsShow}
            activeProgramDescriptionLong={this.state.activeProgramDescriptionLong}
            searchText={this.state.searchText}
            audioOff={this.state.audioOff}
            noOfProgramsInRow={this.state.noOfProgramsInRow}
            programImagesLoaded={this.state.programImagesLoaded}
            categoryImagesLoaded={this.state.categoryImagesLoaded}
            currentRecommenderId={this.state.currentRecommenderId}
            recommendationsList={this.state.recommendationsList}
            currentPrograms={this.state.currentPrograms}
            randomProgramActive={this.state.randomProgramActive}
            pipActivated={this.state.pipActivated}
            onVideoStart={this.handleVideoStart}
            onGalleryElementSelected={this.handleCategoryLoadedOrSelected} 
            onGalleryElementLeave={this.handleGalleryElementLeave}
            onElementHover={this.handleElementHover}
            // onElementLeave={this.handleElementHover}
            onGalleryElementEntered={this.handleGalleryElementEntered}
            onProgramClick={this.handleProgramLoadedOrSelected}
            onElementTaglineHover={this.handleElementTaglineHover}
            onVideoDescriptionsToogleClicked={this.handleVideoDescriptionsToogleClicked}
            onElementTaglineLeave={this.handleElementTaglineLeave}
            onProgramImageChange={this.handleProgramImageChange}
            onCategoryImageChange={this.handleCategoryImageChange}
            onVideoEnded={this.handleVideoEnded}
          ></Gallery>

          <Footer
            gallery={this.state.gallery}
          ></Footer>
        {/* </div> */}
      </div>
    );
  }
}

export default withRouter(props => <App {...props}/>)
