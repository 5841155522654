export const newPrograms = 

[ { previewImg: 'billboard1c', 
programCollectionId: 1009, 
category: 'Dokumentar,Rørende fortællinger', 
sortId: '1,3', 
title: 'KIRSTENS ANSIGT', 
tagLine: 'Kirstens ansigt blev ødelagt af sygdom', 
programDescriptionShort: 'Kirsten har vænnet sig til at folk kigger. Hun har et ”halvt ansigt” som hun selv udtrykker det. Der er direkte hul ind i mundhulen, hvor betændelsen har ædt sig igennem venstre kind. Arvæv trækker hendes nederste øjenlåg nedad, og mundvigen er trukket tilbage, så hun på den ene side aldrig helt kan lukke munden, på den anden side ikke kan gabe helt op', 
programDescriptionLong: 'Kirsten har vænnet sig til at folk kigger. Hun har et ”halvt ansigt” som hun selv udtrykker det. Der er direkte hul ind i mundhulen, hvor betændelsen har ædt sig igennem venstre kind. Arvæv trækker hendes nederste øjenlåg nedad, og mundvigen er trukket tilbage, så hun på den ene side aldrig helt kan lukke munden, på den anden side ikke kan gabe helt op.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 1010, 
category: 'Dokumentar,Musikdokumentarer,Unge', 
sortId: '2', 
title: 'Fribytterdrømmen', 
tagLine: 'Musikdokumentar om bandet Fribytterdrømme', 
programDescriptionShort: 'Fra fuldemandskrammere til drengerøvsdrømme om musikalsk verdensherredømme. Og fra tomme spillesteder i Udkantsdanmark til karrierens højdepunkt: et gig på Roskilde Festival. De 20-årige filmskabere Søren Frederiksen & Pelle D. Johansen har fulgt Svendborg-bandet Fribytterdrømme i tykt og tyndt i et halvt år, hvor forsangeren Lau I.V. Pedersen og hans bedste venner i bandet skal indspille en ny plade og få hverdag og drømme til at hænge sammen. Venskaber og band-idyl bliver sat på prøve undervejs, når de sympatiske Fribytter-drenge kæmper sig vej mod det endelige gennembrud. Laus egen poesi og lyrik er flettet elegant ind i filmen', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 1011, 
category: 'Dokumentar,Portrætter', 
sortId: '3', 
title: 'Dirchs datter', 
tagLine: 'Portræt af Dirch Passers datter Josefine Passer', 
programDescriptionShort: '51-årige Josefine Passer er datter af Danmarks store komiker og filmstjerne Dirch I tre programmer vises unikke og hidtil helt private filmruller', 
programDescriptionLong: '51-årige Josefine Passer er datter af Danmarks store komiker og filmstjerne Dirch I tre programmer vises unikke og hidtil helt private filmruller og Josefine fortæller, hvordan hendes liv og skæbne har formet sig - med og uden sin berømte far. Fra lykkelig barndom, ulykkelig skilsmisse og adskillelse, tragisk dødsfald, rodløshed, konkurs, afsavn og afkald frem til afklarethed, ro og erkendelse og en helt ny begyndelse for Josefine sammen hendes soulmate, Lars.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 1012, 
category: 'Dokumentar,Kultur', 
sortId: '4', 
title: 'KRIGSDANS', 
tagLine: 'Hjemvendte soldater står nu på scenen på Det Kgl. Teater', 
programDescriptionShort: 'Jesper kom hjem fra Afghanistan med PTSD, mens Henrik og Martin mistede ben på grund af miner. Ti år senere befinder de sig på Det Kongelige Teater, hvor de sammen med 30 balletdansere skal skabe en forestilling baseret på egne oplevelser. Det bliver en kæmpe udfordring, især for den psykisk skrøbelige Jesper og den provokerende instruktør Christian Lollike.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 1013, 
category: 'Dokumentar', 
sortId: '6', 
title: 'Victors Verden', 
tagLine: 'Victor er døv, hans mor er døv, hans far er døv… Men hans lillebror kan høre', 
programDescriptionShort: 'Få et enestående indblik i, hvordan det er at være fuldstændig døv. 14-årige Victor klarer sig 100 procent via tegnsprog.', 
programDescriptionLong: 'I programmet Victors Verden får du et enestående indblik i, hvordan det er at være fuldstændig døv. 14-årige Victor klarer sig 100 procent via tegnsprog. TV2 Nord følger ham i hverdagen, og hører om de tanker og drømme han har for fremtiden' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 1014, 
category: 'Dokumentar,Rørende fortællinger', 
sortId: '7', 
title: 'Redningsmanden Nick: Efter ulykken', 
tagLine: 'Nick ville rede to fiskeres liv, men det endte fatalt for ham', 
programDescriptionShort: 'I 2014 kommer 25-årige Nick ud for en alvorlig ulykke, hvor han forsøger at redde to fiskeres liv. Han får lammelser i store dele af kroppen og mister evnen til at tale.', 
programDescriptionLong: 'I 2014 kommer 25-årige Nick ud for en alvorlig ulykke på havnen i Strandby. Han får lammelser i store dele af kroppen og mister evnen til at tale. I to programmer følger vi Nick og familiens seje kamp tilbage til livet og til hverdagen. Kampen giver både op- og nedture. Men med stædighed og vilje kan man nå langt' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 1015, 
category: 'Dokumentar,Historie', 
sortId: '8', 
title: 'Store Danske Videnskabsfolk', 
tagLine: 'Biolog og musiker Johan Olsen rejser tilbage i tiden for at udforske de største danske videnskabsfolk', 
programDescriptionShort: 'Store Danske Videnskabsfolk er en serie, der i hvert afsnit fortæller historien om store danske videnskabsfolk, der var med til at forandre verden', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 1016, 
category: 'Dokumentar,Musikdokumentarer,Kultur,Unge', 
sortId: '9', 
title: 'Se Mig Når Du Sover', 
tagLine: 'Magisk dokuemntar om rapgruppen Fraads', 
programDescriptionShort: 'En dokumentarserie om Frederik og David. Sammen går de igennem en sommer, som forsvinder ind i en musikalsk succes, og en vinter, der lægger sig over København og gør Frederik forkølet.', 
programDescriptionLong: 'På scenen på Roskilde Festival står Frederik og David og spiller deres største koncert nogensinde. Flere hundrede mennesker hopper til musikken og løfter en sky af støv. Sammen er de bandet Fraads. Og drengene står midt i deres ungdom og muligvis deres musikalske gennembrud. Webserien kradser hul ind til drengenes kaotiske og kreative ungdomsunivers. Sammen går de igennem en sommer, som forsvinder i en musikalsk succes, og en vinter, som lægger sig over deres liv og København og gør Frederik forkølet. I hverdagens rammer glider dokumentar og fiktion sammen, når Frederiks søvnløshed tager over, og vi dykker ned i årstidens tilstande af ensomhed og fordybelse.' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 1017, 
category: 'Dokumentar,Portrætter,Kultur', 
sortId: '10', 
title: 'Henning Jensen - En Rejse Så Lang', 
tagLine: 'Forstå mennesket Henning Jensen', 
programDescriptionShort: 'Et filmhold har i et år fulgt Henning Jensen, som hudløst ærligt fortæller om sine svære kampe for at komme tilbage på scenen', 
programDescriptionLong: 'Dette er historien om skuespilleren og privatmennesket Henning Jensen, to ting der hænger så uløseligt sammen. Et filmhold har i et år fulgt Henning Jensen, som hudløst ærligt fortæller om sine svære kampe for at komme tilbage på scenen. Henning Jensen tager os med ned til sit elskede Venedig. Kom med på en spændende rejse, hvor Henning taler åbent om sine op og nedture, om depression og selvmord, men også om den gode kunst og det at leve i nuet.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 1018, 
category: 'Dokumentar,Historie', 
sortId: '11', 
title: 'Carlsberg - får vi så den bajer', 
tagLine: 'Da Carlsberg lukkede bryggeriet i Valby', 
programDescriptionShort: 'Efter 161 år med traditionsrig ølbrygning på toppen af Valby Bakke er det slut. Carlsberg flytter til Frederiksværk. For medarbejderne er det et samfund, som forsvinder.', 
programDescriptionLong: 'Da det gamle, hæderkronede bryggeri, Carlsberg, drejede nøglen om i bryggeriet i Valby i 2008 for at flytte produktionen til Fredericia, var det efter 161 år med traditionsrig ølbrygning på toppen af Valby Bakke. 240 bryggeriarbejdere mistede ved denne lejlighed deres job, og København mistede sin sidste reelle industriarbejdsplads. Vi var med i bryggeriets sidste uger og kommer tæt på både bryggeriarbejderne, ledelsen og på den historie, der har lagt grunden for bryggeriets helt særegne traditioner.' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 1019, 
category: 'Dokumentar,Underholdning', 
sortId: '12,101', 
title: 'I Regimentets Tjeneste', 
tagLine: 'Følg fire unge soldater med hver deres drømme', 
programDescriptionShort: 'Programmet går helt tæt på de fire unge mænd og følger dem i deres op- og nedture på deres vej mod målet. Det er en fortælling om dedikation, tradition og æren ved at være soldat - fortalt gennem hovedpersonernes udfordrende oplevelser.', 
programDescriptionLong: 'Med 2000 soldater er Trænregimentet en vigtig del af det danske forsvar. Hver dag bliver regimentets soldater testet, så de hele tiden er klar til kamp. I reportageserien “I regimentets tjeneste” følger vi fire unge soldater med hver deres drømme. Fire soldater, der konstant møder modstand på deres vej. Det drejer sig om sanitetssoldaten John, der skal bestå det hårde Junior Medic-kursus. Militærpolitieleven Julian, der skal forsøge at kæmpe sig igennem MP-skolen - en af de hårdeste uddannelser i forsvaret. Derudover følger vi den nyuddannede sergent Michael, der for første gang skal lede et hold unge rekrutter. Og så er vi med, når MP’eren Frederik forbereder sig på udsendelse til Kabul i Afghanistan.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 1020, 
category: 'Dokumentar,Klima', 
sortId: '13', 
title: 'Feeding the world', 
tagLine: 'Serien går tæt på madspild og løsningerne', 
programDescriptionShort: 'Med udgangspunkt i de fødevareproblemer verden står overfor, beskriver hver film en række løsninger på hvordan mennesket kan få dækket sit umættelige madforbrug, ved at søge mod nye teknologier, ændre vores forbrugsvaner og spise nye fødevarer.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 1021, 
category: 'Dokumentar', 
sortId: '14', 
title: 'Mayday Mayday Mayday: Nødlandingen i Aalborg Lufthavn', 
tagLine: 'Dokumentar om nødlandingen, der nemt kunne være endt i en katastrofe', 
programDescriptionShort: 'Søndag den 9. september 2007 står stadig som dagen, hvor en flykatastrofe var uhyggeligt tæt på. Gennem fem centrale kilder genfortæller TV2 Nord nu det dramatiske forløb', 
programDescriptionLong: 'Søndag den 9. september 2007 står stadig som dagen, hvor en flykatastrofe var uhyggeligt tæt på. Gennem fem centrale kilder genfortæller TV2 Nord nu det dramatiske forløb – 10 år efter nødlandingen, hvor højre landingsstel kollapsede på SAS-flyet af typen DASH 8. \r\n\r\nDet lignede en helt normal flyvetur fra København til Aalborg, men da hjulene skulle sættes ned få minutter inden landing i Aalborg Lufthavn, ringede alarmklokkerne i cockpittet. \r\n\r\nI over en time hang SAS-flyet over Nordjylland, mens uvisheden hos besætningen, passagerne og pårørende nede på jorden bare voksede og voksede. Det hele endte med billeder fra en dramatisk landing, der gik verden rundt. \r\n\r\nNu tager TV2 Nord dig med helt ind i tankerne hos dem, som oplevede det hele på nærmeste hold. Fra pilotens perspektiv og fotografens optik til indsatslederens frygt og passagerernes panik.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 1022, 
category: 'Dokumentar,Historie', 
sortId: '15,101', 
title: 'D-dag med danske øjne', 
tagLine: 'Den danske indsats under invasionen i Normandiet 1944', 
programDescriptionShort: '”D-dag med danske øjne” handler om den danske indsats under invasionen i Normandiet 1944 med fokus på krigssejlerne og deres betydning for de allieredes anerkendelse af Danmark.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 1023, 
category: 'Dokumentar,Historie', 
sortId: '16,102', 
title: 'Dødens korridorer', 
tagLine: 'Nye og unikke optagelser fortæller et stykke ufortalt dansk verdenshistorie', 
programDescriptionShort: 'Under 1. verdenskrig var den danske kyst et af verdens farligste farvande. 68.000 miner var blevet udlagt af tyskere, englændere og amerikanere i forsøget på at vinde retten til det strategisk meget vigtige farvand omkring Danmark.', 
programDescriptionLong: 'Under 1. verdenskrig var den danske kyst et af verdens farligste farvande. 68.000 miner var blevet udlagt af tyskere, englændere og amerikanere i forsøget på at vinde retten til det strategisk meget vigtige farvand omkring Danmark.\r\nSkibe og ubåde kunne kun bevæge sig rundt om Danmarks kyst via to særligt etablerede korridorer – altså ”mine-fri stier” under havet. Men det var dødsensfarlige togter bådene gik ud på, når de forsøgte at slippe udenom de eksplosive miner på havets bund.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 1024, 
category: 'Dokumentar,Portrætter', 
sortId: '17', 
title: 'Prins Henrik - Et enestående liv', 
tagLine: 'Portræt af prins Henrik', 
programDescriptionShort: 'En smuk skildring af prins Henriks liv, der sluttede 2018. Kom med når de officielle pligter kalder i Danmark og kom tæt på prins Henrik, når dronning Margrethe og familien fortæller om deres forhold til ham, og når han selv fortæller om sit forhold til det danske sprog, til sin familie og sin rolle i kongehuset.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 1025, 
category: 'Dokumentar,Unge', 
sortId: '18', 
title: 'Snyd med kørekort?', 
tagLine: 'Afsløring af fusk på køreskoler', 
programDescriptionShort: 'En række unge fortæller om snyd med antal af lektioner, underskrifter og køretimer, når de har taget deres kørekort. Politiet efterforsker store sager med snyd, og faktisk har branchen efterspurgt ændringer på området i mange år. Der er bare ikke sket noget.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 1026, 
category: 'Dokumentar,Historie', 
sortId: '19', 
title: 'MISSION Mestersvig', 
tagLine: 'Reportage fra Forsvarets øvelse på Mesersvig i Grønland', 
programDescriptionShort: 'Et snedækket Nordøstgrønland lægger grund til en øvelse for det arktiske beredskab. Deltagerne kommer bl.a. fra Frømandskorpset, Jægerkorpset og Aalborg Universitet.', 
programDescriptionLong: 'Et snedækket Nordøstgrønland lægger grund til en øvelse for det arktiske beredskab. Deltagerne kommer bl.a. fra Frømandskorpset, Jægerkorpset og Aalborg Universitet.\r\n\r\nTV2 Nord fulgte en række af de nordjyske enheder, som i september 2015 trænede arktisk overlevelse i nationalparken i Østgrønland under øvelsen Arctic Response' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 1027, 
category: 'Dokumentar,Liv & Død,Kontroversielt', 
sortId: '20', 
title: 'Din død, dit valg?', 
tagLine: 'Per har seks måneder tilbage. Må han vælge at begå selvmord?', 
programDescriptionShort: 'Aktiv dødshjælp  er ulovligt i Danmark, og derfor blev den pensionerede fynske læge Svend Lings dømt ved Højesteret, da han hjalp patienter med at begå selvmord. Men hvem skal bestemme hvornår ens liv skal slutte?', 
programDescriptionLong: 'Hvem skal bestemme hvornår dit liv slutter?\r\nI denne mini-dokumentar tager Bemærk●Dokumentar / Tv 2 Fyn hul på et følsomt emne: Aktiv dødshjælp. Det er ulovligt i Danmark, og derfor blev den pensionerede fynske læge Svend Lings dømt ved Højesteret, da han hjalp patienter med at begå selvmord. \r\n\r\nObs. Hvis du er i krise eller har tanker om selvmord, så sig det til nogen! Du kan kontakte Livsliniens telefonrådgivning på telefonnummer: 70 201 201 alle dage fra klokken 11-04, ligesom du har mulighed for at chatte med Livslinien.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 1028, 
category: 'Dokumentar,Historie', 
sortId: '21,103', 
title: 'Historien om August Krog', 
tagLine: 'Den danske nobelprisvinder', 
programDescriptionShort: 'August Krog havde unikke evner til at forstå verden omkring sig og genskabe den i laboratoriet. Det gjorde ham til en genial og prisvindende forsker. Selv mente han, at det var kærligheden til sandheden og til mennesker, der var det vigtigste i livet', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 1029, 
category: 'Dokumentar,Historie', 
sortId: '22,104', 
title: 'Jeg glemmer det aldrig - en modstandsmands erindringer', 
tagLine: 0, 
programDescriptionShort: 'Gustav Holmberg oplevede 2. Verdenskrig på klos hold', 
programDescriptionLong: 'Frihedskæmperen deltog i arbejdet med nedkastninger, han var med under augustoprøret, tilskuer til en likvidering og lå i krydsild på Flakhaven, mens kuglerne piftede om ørene på ham, på befrielsesdagen den 5. maj. \r\nGustav Holmberg fortæller hvordan han blev engageret i kampen for danmark' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 1030, 
category: 'Dokumentar,Livstil', 
sortId: '23', 
title: 'Alchemist - sådan lavede han verdens vildeste restaurant', 
tagLine: 'Da Restaurant Alchemist åbnede for booking, blev der udsolgt på 4 minutter', 
programDescriptionShort: 'Et TV-hold har fuldt chefkok Rasmus Munk i et år, mens han har kæmpet for at åbne sin nye restaurant. Målsætningen er at give gæsterne 50 oplevelser, der skal få dem til at fundere over verden - herunder bæredygtighed, madproduktion og plastik i havene.', 
programDescriptionLong: 'Da Restaurant Alchemist åbnede for booking, var der udsolgt på fire minutter, og 6500 skrev sig på ventelisten til et bord.\r\n\r\nOg da restauranten endelig slog bronzedørene op den 4. juli på Refshalenøen, kunne anmelderne knapt få armene ned.\r\n\r\nAmerikanske Vanity Fair beskrev oplevelsen som magisk, mens engelske Independent kaldte Alchemist for "molekylærgastronomiens filosofiske genstart".\r\n\r\nHerhjemme mente Børsen, at Alchemist bør blive en lige så stor attraktion som Noma og Geranium, mens Berlingske gav fuldt hus: Seks stjerner.\r\n\r\nEt TV-hold har fuldt chefkok Rasmus Munk i et år, mens han har kæmpet for at åbne sin nye restaurant. Målsætningen er at give gæsterne 50 oplevelser, der skal få dem til at fundere over verden - herunder bæredygtighed, madproduktion og plastik i havene. \r\n\r\nPå restauranten skal du blandt andet slikke maden af en tunge, åbne en hovedbund og smage på spiselig plastik. \r\n\r\nMød i dokumentaren, foruden Rasmus Munk, pengemanden Lars Seir Christensen, der har brugt 65 millioner kroner på projektet.' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 1031, 
category: 'Dokumentar,KulturUnge', 
sortId: '24', 
title: 'Dansen I Os', 
tagLine: 'Kom tæt på Det Kgl. Teaters Balletskole', 
programDescriptionShort: 'Dokumentar om det unge ensemble, Kompagni B. Kom bag om tilværelsen som elitært stjernefrø og oplev hvordan balleteleverne udvikler sig som spirende koreografer', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 1032, 
category: 'Dokumentar,Identitet,Historie', 
sortId: '25201', 
title: 'Regnbuen over København - 100 års homohistorie', 
tagLine: 'Sådan blev Danmark frisindet', 
programDescriptionShort: 'København har i mere end 100 år været centrum for de homoseksuelles ligestillingskamp. Homohistorien er en fortælling om skandaler, forfølgelse, undertrykkelse og menneskelige skæbner. Men det er også en udviklingshistorie om Danmark, og hvordan landet er blevet formet som en moderne nation.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 1033, 
category: 'Dokumentar,Kultur,Portrætter', 
sortId: '26', 
title: 'Carl-Henning Pedersen - den spontane maler', 
tagLine: 0, 
programDescriptionShort: 'Se dokumentarfilmen Den Spontane Maler. Her møder man bl.a. Carl-Henning Pedersens enke og John Körner, som er stærkt inspireret af Cobra-malerens spontane og farvestrålende malerier.', 
programDescriptionLong: 'Se dokumentarfilmen Den Spontane Maler, som blev vist på DR K i sidste uge. Her møder man bl.a. Carl-Henning Pedersens enke og John Körner, som er stærkt inspireret af Cobra-malerens spontane og farvestrålende malerier. Den danske kunstmaler Carl-Henning Pedersen var aktiv, helt frem til sin død i 2007 - 94 år gammel.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 1034, 
category: 'Dokumentar,Historie', 
sortId: '27,105', 
title: 'Møllers Sidste Skib', 
tagLine: 'Da Danmarks største værft blev lukket', 
programDescriptionShort: 'Møllers Sidste Skib er historien om Lindøværftet og de mennesker, der kæmpede for det. Vi kommer tæt på de konsekvenser, lukningen af Danmarks største skibsværft har', 
programDescriptionLong: 'Møllers Sidste Skib er historien om Lindøværftet og de mennesker, der kæmpede for det. Vi kommer tæt på de konsekvenser, lukningen af Danmarks største skibsværft har – ikke blot for værftets 6000 medarbejdere men for os alle sammen. Historien om Lindøværftet er nemlig ikke enestående. Tusinder af produktionsarbejdspladser forsvinder hvert år ud af Danmark og efterlader os med spørgsmålet: Hvad skal vi leve af i fremtiden?' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 1035, 
category: 'Dokumentar,Unge', 
sortId: '5', 
title: 'Befri dit liv', 
tagLine: 'Julie lever livet uden forpligtelser, regninger eller fast adresse', 
programDescriptionShort: 'Julie og hendes kæreste Mánus gør lige, hvad der passer dem.\r\n\r\nKæresteparrets vil “befri sit liv fra rotteræset”, som Julie oplever, at mange andre mennesker løber rundt i. Derfor lever de et liv uden forpligtelser overhovedet.', 
programDescriptionLong: 'Julie og hendes kæreste Mánus gør lige, hvad der passer dem. Mánus er ikke født dansker, men fra England og vokset op i Spanien og ved at lære dansk. De har ingen forpligtelser og har kun ét fast fundament i livet: Sæsonarbejde på Samsø. Fra maj til oktober arbejder de igennem som sæsonarbejdere på øen for at tjene penge til resten af året. Når sæsonen med arbejde er slut, rejser de til lande, som er billige at leve i (fx Marokko eller Thailand). Deres mål er at være “seks måneder ude og seks måneder hjemme” samt at nyde det kaotiske men fri nomadeliv som livsstil. Julie er uddannet tatovør og har altid sin tatoveringsmaskine med sig, så hun kan udtrykke sin kunst og samtidig tjene penge på at lave en tattoo eller to, hvor end hun er i verden. Både hun og Mánus har ligeledes selv prydet kroppen med flotte tatoveringer. Kæresteparrets formål er at “befri sit liv fra rotteræset”, som Julie oplever, at mange andre mennesker løber rundt i. For hende og Mánus er det en livsstil at gøre præcis, som det passer dem - ingen forpligtelser overhovedet.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 1036, 
category: 'Dokumentar', 
sortId: '', 
title: 'Døden tur/retur', 
tagLine: 'Hvad sker der, når man dør?', 
programDescriptionShort: 'I denne dokumentar kan du møde tre mænd, der hver især har prøvet at dø - og komme tilbage til livet. Her er deres historier.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 1041, 
category: 'Dokumentar', 
sortId: '28', 
title: 'De skjulte hjemløse', 
tagLine: 'Stadig flere kvinder mangler tag over hovedet', 
programDescriptionShort: 'En stor gruppe hjemløse kvinder klarer sig igennem natten ved at jagte sofaer hos familie, venner – eller helt fremmede. Et TV-hold har fulgt to af kvindernes jagt – og den sideløbende kamp for rollen som veninde, datter og ikke mindst mor.', 
programDescriptionLong: 'En stor gruppe hjemløse kvinder klarer sig igennem natten ved at jagte sofaer hos familie, venner – eller helt fremmede.\r\n\r\nFra 2009 til 2017 er antallet af hjemløse kvinder i Danmark steget fra 1.066 til 1.633. Knapt halvdelen af disse lever som såkaldte sofasurfere, hvorfor de ikke ses på gaden om natten eller registreres på byens herberger.\r\n\r\nDe er hjemløse i det skjulte, og mange befinder sig her i hovedstadsområdet. \r\n\r\nTV 2 Lorry har fulgt to af kvindernes jagt – og den sideløbende kamp for rollen som veninde, datter og ikke mindst mor.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 2001, 
category: 'Historie', 
sortId: '5', 
title: 'Frederik 4. Den Pigeglade Konge', 
tagLine: 'Få hele historien her', 
programDescriptionShort: 'Frederik 4. skal finde en dronning, og han rejser til det tyske rige, hvor han vælger Louise. Hun er dog ikke særlig spændende, og Frederik bliver forelsket i den 22 år yngre Anna Sofie, som han ægter til sin venstre hånd og begår på den måde bigami. Da Frederik dør, bortvises Anna Sofie til fødehjemmet Clausholm, og hun bliver pålagt livslangt eksil', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 2002, 
category: 'Historie', 
sortId: '6', 
title: 'Christian 7. Den Syge Konge', 
tagLine: 'Få hele historien her', 
programDescriptionShort: 'Christian d. 7. lider af en sindssygdom og hoffet prøver at tage magten fra ham. Især den sure enkedronning gør livet surt for Christian. Men så møder Christian en læge, som hjælper ham med at styre kongeriget. Desværre tager lægen lidt for meget magt og han bliver kæreste med dronning Caroline Mathilde, og så går alt galt', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 2003, 
category: 'Historie', 
sortId: '1', 
title: 'De Uartige Kalkmalerier', 
tagLine: 'Frække fantasier i fortidsmalerier', 
programDescriptionShort: 'Kvinder med bare bryster. Djævle, der skider. Mænd, der onanerer. I "De Uartige Kalkmalerier" tager kunsthistorikeren Peter Kær og ekspert i kalkmalerier, Torben Svendrup, os med tilbage til en tid, der slet ikke var så kysk og kedelig, som vi tror', 
programDescriptionLong: 'Kvinder med bare bryster. Djævle, der skider. Mænd, der onanerer. I "De Uartige Kalkmalerier" tager kunsthistorikeren Peter Kær og ekspert i kalkmalerier, Torben Svendrup, os med tilbage til en tid, der slet ikke var så kysk og kedelig, som vi tror.\r\nFor 600 år siden var de danske kirker et frirum, hvor det var bønderne, der bestemte motiverne i kalkmalerierne. Med udgangspunkt i kalkmalerierne viser de to historikere hvordan man i Middelalderen forholdt sig noget mere frit til sex, druk, hån og humor, end de fleste af os antager.' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 2004, 
category: 'Historie,Underholdning,Krimi', 
sortId: '7,104', 
title: 'Mord i Middelalderen', 
tagLine: 'Peter Kær ‘efterforsker’ mord fra fortiden', 
programDescriptionShort: 'Peter Kær undersøger bl.a. mordet på Danmarks ”statsminister” og en af landets rigeste mænd', 
programDescriptionLong: 'Værten og kunsthistorikeren Peter Kær følger sammen med historikeren Torben Svendrup de historiske spor til gerningssteder, storslåede kirker og krypter, hemmelighedsfulde grave og berømte monumenter. De kigger også på malerier og krøniker og læser i originale tekster fra middelalderen. Alt sammen for at blive klogere på en række spektakulære mord, hvem der stod bag dem og ikke mindst, hvorfor de skete.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 2005, 
category: 'Historie', 
sortId: '3', 
title: 'Rosenborg - Christian 4. og \'Det store hus i Haven\'', 
tagLine: 'Da Christian 4. skabte Rosenborg', 
programDescriptionShort: 'Christian 4. startede som en af Europas rigeste fyrster og endte med at have tabt både landsdele, ære og hele sin formue. Få hele historien her.', 
programDescriptionLong: 'Christian 4. startede som en af Europas rigeste fyrster og endte med at have tabt både landsdele, ære og hele sin formue. Og alligevel er han blevet en af Danmarkshistoriens populæreste konger. Hvorfor det? Filmen giver en del af svaret.\r\nHer fortælles om kongen som bygherre, hvor det mere intime private sommerslot Rosenborg nok stod hans hjerte nærmest.\r\nFilmen beskriver endvidere kongens komplicerede kærlighedsliv, med dronning Anna Catrine, hustruen ‘til venstre hånd’ Kirsten Munk og flere elskerinder.' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 2006, 
category: 'Historie', 
sortId: '8', 
title: 'Et Raådhus til alvor og Fest', 
tagLine: 'Kom bag opførelsen af Københavns Rådhus', 
programDescriptionShort: 'En film om Københavns Rådhus, som er et af den danske nationalromantiks hovedværker indenfor bygningskunsten, skabt af arkitekten Martin Nyrop i årene 1890-1905.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 2007, 
category: 'Historie', 
sortId: '4', 
title: 'Arkitekten der blev Væk', 
tagLine: 'Den anonyme arkitekt bag Amalienborg', 
programDescriptionShort: 'Arkitekten Nicolai Eigtved (1701-54) var rokokoens mester. Han var som hofarkitekt kollega med den lige så talentfulde barok-arkitekt Lauritz de Thurah (1704-59). Marmorbroen, Amalienborg-palæerne og Frederiksstaden hører til hans kendteste arbejder.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 2008, 
category: 'Historie,Dokumentar,Identitet', 
sortId: '2,101', 
title: 'Vejen er Lang', 
tagLine: 'Kvindebevægelsens historie', 
programDescriptionShort: 'Vejen er Lang stiller skarpt på kvindebevægelserne i Danmark fra blåstrømper og rødstrømper til queer-feminister og nutidens unge', 
programDescriptionLong: 'I 2015 er det 100 år siden, danske kvinder opnåede stemmeret. Inspireret af datoen stiller Vejen er Lang skarpt på kvindebevægelserne i Danmark fra blåstrømper og rødstrømper til queer-feminister og nutidens unge kvinder og mænd. \r\nDer løber en rød tråd mellem det 19. og det 20. århundredes to store kvindebevægelser. Begge kæmper for at opnå formelle rettigheder og for at få retten over deres egen krop. Begge opstår i kølvandet på lignende oprør andre steder i verden. Og begge starter som en lille håndfuld aktivister, der ender med at rejse landsdækkende bevægelser, som skelsættende og for altid ændrer en hel befolknings livsbetingelser juridisk, økonomisk og seksuelt. \r\nDette er også en personlig serie, hvor instruktøren Mette Knudsen 40 år efter begivenhederne sætter den Rødstrømpebevægelse, som hun selv var en del af, ind i et større og nutidigt perspektiv ved at trække 100 års kvindehistorie frem og ved at undersøge, hvor unge kvinder og mænd i dag er på vej hen. Er vi i mål? Eller hvor er vi på vej hen?' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 2009, 
category: 'Historie', 
sortId: '9', 
title: 'Godhavnsdrengene: Tæsket, misbrugt og medicineret', 
tagLine: '"I stedet for kærlighed fik vi tæsk"', 
programDescriptionShort: 'Fysisk og psykisk terror, svigt og vanrøgt var en del af dagligdagen på børnehjemmet Godhavn i Tisvildeleje, der er blevet berygtet for det helvede og svigt, som børnene blev udsat for i perioden fra 1950-1975 under ledelse af en forstander, som beskrives som en satan af \'Godhavnsdrengene\'.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 2010, 
category: 'Historie,Bliv klogere', 
sortId: '10', 
title: 'Vores Hovedstad. Forfra', 
tagLine: 'Bliv klogere på historiske facts', 
programDescriptionShort: 'I fem explainere tager unge eksperter os med gennem fascinerende historiske begivenheder. Bliv f.eks. klogere på om pest eller kolera nu også er lige dårlige valgmuligheder.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 3001, 
category: 'Underholdning,Unge', 
sortId: '1', 
title: 'Først Til Faxen', 
tagLine: 'Gameshow med kendte Youtubere', 
programDescriptionShort: 'I ‘Først til Faxen’ dyster to kendte deltagere i en VILD forhindringsbane. Det handler om at være hurtigst, for der er en KLAM eller UBEHAGELIG straf på spil!', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 3002, 
category: 'Underholdning,Unge', 
sortId: '8', 
title: 'SÅ KA\' DU LÆRE DET', 
tagLine: 'Vanvittige challenges til kendte Youtubere', 
programDescriptionShort: 'I hvert afsnit af SÅ KA\' DU LÆRE DET får to nye, kendte deltagere en opgave af YouKondi. De skal lære noget nyt, som de aldrig troede, at de ville kunne.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 3003, 
category: 'Underholdning,Unge', 
sortId: '5', 
title: 'Rekordjægerne', 
tagLine: 'Grineren verdensrekorder', 
programDescriptionShort: 'Rekordjægerne er serien hvor kendte  youtubere får muligheden for at at slå hinanden, og for at slå ægte verdensrekorder!', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 3004, 
category: 'Underholdning', 
sortId: '', 
title: 'Blaffersnak', 
tagLine: 'Hvem møder man, når man blaffer', 
programDescriptionShort: 'Kom med på tur, når TV 2 Lorry-journalist Niels Christian Buhl og en af Danmarks mest erfarne blaffere, Carsten Theede, stikker tommelfingeren frem og blaffer rundt på sjælland. For at få en oplevelse og en vaskeægte blaffersnak.', 
programDescriptionLong: 'Har du nogensinde taget en blaffer op – eller måske selv taget den på tomlen? København har fået fire nye blafferstationer, hvor bilister kan samle blafferne op, men hvem er det egentlig, der stopper op og giver et lift?\r\n\r\nKom med på tur, når TV 2 Lorry-journalist Niels Christian Buhl og en af Danmarks mest erfarne blaffere, Carsten Theede, stikker tommelfingeren frem og blaffer rundt på sjælland. For at få en oplevelse og en vaskeægte blaffersnak.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 3005, 
category: 'Underholdning,Unge', 
sortId: '7', 
title: 'Just Add Some Cheese', 
tagLine: 'Kendte dyster om at lave osteretter', 
programDescriptionShort: 'DEN ULTIMATIVE OSTEKONKUR­RENCE: 3 kendte dyster mod hinanden om hvem, der kan lave de mest opfindsomme osteretter. Den bedste ostekender, med de mest opfindsomme ideer og lækreste opskrifter VINDER.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 3006, 
category: 'Underholdning,Unge', 
sortId: '6', 
title: 'STALKER - Internettet glemmer aldrig', 
tagLine: 'Værten finder pinlige ting om kendte', 
programDescriptionShort: 'I serien STALKER bliver en kendt stalket for at finde pinlige, flove og grove ting, som den kendte tror, internettet har glemt.', 
programDescriptionLong: 'Vi ved det alle sammen godt: Internettet glemmer aldrig! På sociale medier som YouTube, Instagram og Facebook lægger vi selv billeder, videoer og tekst ud, som aldrig bliver glemt. Andre kan også tagge os i deres opslag, så vi kan ikke altid holde styr på, hvad internettet husker om os. Når man tilmed er kendis, bliver der skrevet endnu mere om en, og det kan være svært at styre, hvilke artikler der bliver udgivet. En simpel googlesøgning kan hurtigt vise artikler, billeder og videoer, som man egentlig helst ville glemme. Der ligger gamle skolefotos af dig derude, pinlige facebookopdateringer og akavede blogposts på den hjemmeside, du aldrig fik lukket. I serien STALKER er Anne Troensegaard taget ud for at stalke en kendt og finde tre ting, som den kendte tror, internettet har glemt. Hun finder det sjoveste, det floveste og det groveste om den kendte via sin stalking. Hun bruger lang til på at stalke de kendte på sociale medier, og hun har de helt rette detektiv-skills til at finde de bedste historier, som den kendte troede, at internettet havde glemt.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 3007, 
category: 'Underholdning,Unge', 
sortId: '9', 
title: 'Min Klub', 
tagLine: 'Frederikke Toftsø vil være fodbold-fan', 
programDescriptionShort: 'Bloggeren Frederikke Toftsø er træt af ikke at vide noget om fodbold. Hun har derfor sat sig for at lære noget om det, og at finde en klub at holde med', 
programDescriptionLong: 'I Min Klub rejser blogeren Frederikke Tofsø i 6 programmer ud i Europa for at besøge 6 forskellige fodboldklubber. Frederikke er træt af ikke at vide noget om fodbold. Hun har derfor sat sig for at lære noget om det, og at finde en klub at holde med. 6 danske landsholdsspillere har inviteret Frederikke på besøg i deres respektive klubber for at overbevise hende om, at hun skal blive fan af netop deres klub.' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 3008, 
category: 'Underholdning,Unge', 
sortId: '10', 
title: 'INFLUENCERS', 
tagLine: '4 influenceres liv. Hudløst ærligt!', 
programDescriptionShort: 'I serien får du et autentisk indblik i fire danske influencers uredigerede hverdag. Kom bag facaden på Irina B. Olsen aka. @IrirnaTheDiva, Frederik Trudslev, Astrid Olsen, Camilla Frederikke', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 3009, 
category: 'Underholdning,Unge', 
sortId: '11', 
title: 'LevLivetHurtigere', 
tagLine: 'Nederen ting løses på grineren måder', 
programDescriptionShort: 'I LevLivetHurtigere ’hacker’ MadsenGaming og Eiqu Miller hacke deres morgen, aften og weekendrutiner - for i sidste ende at få meget mere tid til at game!', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 3010, 
category: 'Underholdning,Unge,Fashion', 
sortId: '3', 
title: 'Styleduellen', 
tagLine: 'Hvem kan lave de fedeste styles?', 
programDescriptionShort: 'I Styleduellen dyster to hold af Danmarks største youtubere indenfor style om at skabe det fedeste look', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 3011, 
category: 'Underholdning,Livstil', 
sortId: '', 
title: 'Bye Bye Baby', 
tagLine: 'Damer i Las Vegas – uden børn', 
programDescriptionShort: '', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 4001, 
category: 'Fiktion,Unge', 
sortId: '3', 
title: 'Vakuum', 
tagLine: 'Stærk ungdomsserie', 
programDescriptionShort: 'Vakuum\' er en fiktionsserie, der følger tre unge, Eva, Anton & Karl, der hver især oplever noget skelsættende i deres tilværelse.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 4002, 
category: 'Fiktion,Unge', 
sortId: '2', 
title: 'GG Horsens', 
tagLine: 'Gamer-serien alle taler om', 
programDescriptionShort: '', 
programDescriptionLong: 'GG Horsens er en farverig dansk CSGO serie af instruktør Morten Urup. Denne gaming serie foregår i det sprudlende e-sport miljø og er en sjov fortælling om at drømme stort og blive den bedste. I GG Horsens følger vi Sandra (Julie Zangenberg), der ønsker at leve af at spille Counter-Strike. Vi rejser med hende ind i det tempofyldte CSGO spil, hvor hun sammen med det kiksede hold GG og deres e-sport træner Henning (Jonas Schmidt) forsøger at kæmpe sig til tops og slå det store Team Bad-@$$ med gamerne Wallhack (Albert Dyrlund), Sweet-E (Morten Münster) og T-Dog (Casper Harding fra Spørg Casper) samt træneren Poulsen (Andreas Bo). På seriens rolleliste finder vi bl.a. også Christian Fuhlendorff, Niels Olsen, Sofie Stougaard, Thomas Ernst og Anders Brink Madsen.' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 4003, 
category: 'Fiktion,Comedy,Underholdning', 
sortId: '4,12,201', 
title: 'Lige Over Grænsen', 
tagLine: 'Comedy-serie med Casper C.', 
programDescriptionShort: 'Lige over grænsen er en fiktiv dokumentarserie – eller ’mockumentary’. Serien følger Casper Christensen og de mange vanskeligheder, han får rodet sig ud i', 
programDescriptionLong: 'Lige over grænsen er en fiktiv dokumentarserie – eller ’mockumentary’. Serien følger Casper Christensen, som skal have lavet swimmingpool derhjemme, men han mangler pengene. Derfor siger han ja til et reklamejob for grænsebutikken Fleggaard. Hurtigt ender han dog i store vanskeligheder, da chefens datter truer med at afslører en video, han bestemt ikke ønsker skal afsløres.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 4004, 
category: 'Fiktion', 
sortId: '1', 
title: 'Fastland', 
tagLine: 'Natten glemmer aldrig', 
programDescriptionShort: 'Stærk ungdomsserie fra 2017', 
programDescriptionLong: 'Fastland er en dansk ungdomsserie fra 2017. Serien blev rost af anmelderne og er bl.a. blevet sammenlignet med Skam' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 4005, 
category: 'Fiktion', 
sortId: '5', 
title: 'Første gang', 
tagLine: 'Serien handler om 4 teenage-veninder', 
programDescriptionShort: 'Serien handler om Sofie, Alma, Clara og Lærke, som går på den samme efterskole. Den hedder ”Første gang”, fordi den handler om de første gange, mange oplever som teenager.', 
programDescriptionLong: 'Serien handler om Sofie, Alma, Clara og Lærke, som går på den samme efterskole. Den hedder ”Første gang”, fordi den handler om de første gange. F.eks. den første kæreste, et hårdt breakup, tætte venskaber, at få overskredet sine grænser og meget mere.\r\n\r\nManuskriptet er skrevet af Christina Sederqvist, som også stod bag DR Ultra-serien ’Klassen’. Serien er instrueret af Morten Sabroe.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 4006, 
category: 'Fiktion,Comedy', 
sortId: '7,11', 
title: 'Forældremanualen', 
tagLine: 'En baby kommer ikke med en brugermanual', 
programDescriptionShort: 'Thomas Skov og hans ven Martin Johannes gerne skal lave en forældremanual med simple svar. Men de er måske ikke de mest oplagte til opgave…', 
programDescriptionLong: 'En baby kommer ikke med en brugermanual. Men det vil Thomas Skov og hans ven Martin Johannes gerne ændre på. Følg de to venner jagte simple svar til Forældremanualen, som de har fået til opgave at lave for Arla Baby&Me Organic' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 4007, 
category: 'Fiktion,Underholdning', 
sortId: '6,102', 
title: 'Mindre sol - med Monte Carlo-drengene', 
tagLine: 0, 
programDescriptionShort: '', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 5001, 
category: 'Sportsdokumentarer,Unge', 
sortId: '6', 
title: 'GULDDERBYET', 
tagLine: 'DOKUMENTARFILM AF FCK TV', 
programDescriptionShort: 'Søndag den 5. maj 2019: En sejr over Brøndby ville sikre DM-guldet og gøre det til en historisk dag for FCK. Det blev det til sidst - men først efter et DERBY-drama af de helt store, der fremkaldte endnu større følelser.', 
programDescriptionLong: 'Scenen var sat. Det stod næsten skrevet i stjernerne søndag den 5. maj 2019. En sejr over Brøndby ville sikre DM-guldet, og alt var linet op til, at det ville blive en historisk dag i København. Og det blev det til sidst - men først efter et DERBY-drama af de helt store, der fremkaldte endnu større følelser hos alle på banen og på tribuerne i Telia Parken.\r\n \r\nI denne 40 minutter lange dokumentarfilm får du HELE historien om GULDDERBYET - dagen, da mesterskabet kom hjem til Hovedstaden. Genfortalt af spillerne selv og med kameraer overalt før, under og efter opgøret. Det er den ultimative dækning af den største kamp, hvor alt gik op i højere enhed - og hvor det endte med glædestårer af guld. God fornøjelse!' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 5002, 
category: 'Sportsdokumentarer,Unge', 
sortId: '1', 
title: 'Drafted - Historien om Hjalte Froholdt', 
tagLine: 'FØLGER HJALTE FROHOLDT FØR NFL DRAFTEN', 
programDescriptionShort: 'Et TV-hold følger Hjalte Froholdt i dagene op til draften, hvor han bliver valgt af New England Patriots.', 
programDescriptionLong: 'Bemærk Dokumentar følger Hjalte Froholdt i dagene op til draften, hvor han bliver valgt af New England Patriots. Bemærk er lige i hælene, når Hjalte Froholdt for alvor går "from Denmark to the NFL" som den blot anden dansker nogensinde i historien. Efter draft combines og imponerende highlights med Arkansas Razorbacks får han endelig "the call", der sender ham til en af de største klubber i NFL-ligaen. \r\n\r\nTV-holdet besøger også hans college, og får et kig indenfor ved Arkansas Razorbacks\' træningsfaciliteter. For Hjalte Froholdt har jagten på NFL fyldt det meste af hans teenage- og voksenliv, siden han for første gang kastede sig over sporten i Svendborg Admirals-klubben.\r\n\r\nDermed har Hjalte Froholdt fra Svendborg gjort noget, der nærmest er umuligt. Han skrev sportshistorie, da han som den blot anden dansker nogensinde, blev draftet til NFL - den bedste liga for amerikansk fodbold.\r\n\r\nFor amerikanere, der spiller amerikansk fodbold på deres high school, er chancen for at blive draftet til NFL på cirka 0,02 procent. Alligevel lykkedes det for en fynbo fra Svendborg.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 5003, 
category: 'Sportsdokumentarer,Unge', 
sortId: '5', 
title: 'Drømmen om Juventus', 
tagLine: 'På træningsbanen med Cristiano Ronaldo', 
programDescriptionShort: '19-årige Nikolai Baden Frederiksen drømmer om en plads på førsteholdet i Juventus F.C., hvor han blandt andre kan komme til at spille sammen med Cristiano Ronaldo.', 
programDescriptionLong: '19-årige Nikolai Baden Frederiksen drømmer om en plads på førsteholdet i Juventus F.C., hvor han blandt andre kan komme til at spille sammen med Cristiano Ronaldo, der flere gange er blevet kåret til verdens bedste fodboldspiller.\r\n\r\nI 2018 blev den 19-årige odenseaner Nikolai Baden Frederiksen solgt til den italienske storklub Juventus F.C., som blandt andre har den tidligere Ballon d\'Or-modtager Cristiano Ronaldo på holdkortet. Det var en stor omvæltning fra danske FC Nordsjælland, hvor han spillede i to år. \r\n\r\nSiden Nikolai Baden Frederiksen kom til Torino i det nordlige Italien, hvor Juventus F.C. holder til, har han trænet med både klubbens andet- og førstehold, men han har endnu ikke fået kampdebut for klubbens bedste hold, som spiller i Serie A og lige nu ligger nummer to - med lige så mange point som ligaens nummer ét, Inter. \r\n\r\nDet er et stort psykisk pres, man er under som fodboldspiller i en professionel klub som Juventus F.C., hvor man hele tiden er i konkurrence med sine holdkammerater, fordi der kun er plads til 11 mand i startopstillingen. Det gør, at man skal præstere 24-7, og indtil nu mangler Nikolai Baden Frederiksen stadig at levere på dét niveau. Sker det ikke, skal han finde en ny klub.' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 5004, 
category: 'Sportsdokumentarer', 
sortId: '6', 
title: 'Aage Rou Jensen - aldrig færdig med fodbold', 
tagLine: 'Historien om den store AGF\'er', 
programDescriptionShort: 'Historien om Aage Rou Jensen, der spillede i AGF fra 1942 og tyve år frem. Han vandt fire mesterskaber, tre pokaler og stod blandt andet i spidsen for det hold, der nåede kvartfinalen i Mesterholdenes Europa Cup.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 5005, 
category: 'Sportsdokumentarer', 
sortId: '4', 
title: 'Guldjakken', 
tagLine: 0, 
programDescriptionShort: '', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 5006, 
category: 'Sportsdokumentarer', 
sortId: '10', 
title: 'Sammen på Sidelinjen - Wind & Boilesen', 
tagLine: 'Jonas Wind og Nicolai Boilese var begge skadet', 
programDescriptionShort: '', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 5007, 
category: 'Sportsdokumentarer', 
sortId: '9', 
title: 'BASHKIM - VEJEN TILBAGE', 
tagLine: 'Historien om Bashkim Kadriis lange og seje kamp for et comeback', 
programDescriptionShort: '', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 5008, 
category: 'Sportsdokumentarer', 
sortId: '11', 
title: 'WE MADE HISTORY', 
tagLine: 'Kvartfinalen: FCK - Manchester United', 
programDescriptionShort: '', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 5009, 
category: 'Sportsdokumentarer', 
sortId: '8', 
title: 'Cathrine Dufour the Story', 
tagLine: 'Historien om en OL-rytter', 
programDescriptionShort: '', 
programDescriptionLong: 'Hestebranchen har i årevis været præget af, at det kun er ryttere født ind i velhavende familier, som har mulighed for at blive professionelle ryttere. Det har den danske OL-deltager Cathrine Dufour gjort op med. Hun kommer fra en normal familie med en normal økonomi og uden særligt kendskab til heste. I denne dokumentar lukker hun folk ind i både sit private og professionelle liv.' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 5010, 
category: 'Sportsdokumentarer,Unge', 
sortId: '2', 
title: 'FC Midtjylland – oprøret fra Heden', 
tagLine: 'Midjyllands vilde 2019', 
programDescriptionShort: 'Følg med når et TV-hold kommer helt ind under huden på titelkandidaten med enorme ambitioner i FC Midtjylland – opgøret fra Heden.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 5011, 
category: 'Sportsdokumentarer,Unge', 
sortId: '3', 
title: 'AGF – mellem håb og håbløshed', 
tagLine: '"De hvide" vil tilbage', 
programDescriptionShort: '', 
programDescriptionLong: 'I AGF – mellem håb og håbløshed går et TV-hold tæt på ”de hviiees” drøm om at vende tilbage til toppen af dansk fodbold, når AGF åbner dørene til hidtil usete sider af klubben.' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 6001, 
category: 'Comedy,Underholdning,Klima,Unge', 
sortId: '1,105', 
title: 'WTF gør vi med klimaet', 
tagLine: 'Magnus Millang vs. Klimaet', 
programDescriptionShort: '', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 6002, 
category: 'Comedy,Underholdning,Unge', 
sortId: '3,103', 
title: 'Dybvad\'s Sofa', 
tagLine: 0, 
programDescriptionShort: '', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 6003, 
category: 'Comedy,Underholdning,Unge', 
sortId: '2,106', 
title: 'Hva\' Fanden', 
tagLine: 'Græd eller grin med Magnus Millang', 
programDescriptionShort: 'Magnus Millang  og hans bror tager ud og undersøger, hvad vi skal være bange for, og hvordan vi bør ændre vores hverdag.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 6004, 
category: 'Comedy,Underholdning,Fiktion', 
sortId: '51,107,201', 
title: 'Villum & Schmidt i Alperne', 
tagLine: 'Comedy-serie med Jonas Schmidt og Thomas Villum', 
programDescriptionShort: 'Kom med Villum & Schmidt til Alperne. Se om Jonas Schmidt får lært at stå på ski, når de besøger 5 Alpe-klassikere på kun 10 dage.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 6005, 
category: 'Comedy,Livstil', 
sortId: '52', 
title: 'Villum & Schmidt på Mølleåen', 
tagLine: 0, 
programDescriptionShort: '', 
programDescriptionLong: 'De to rejsevenner skal denne gang sejle i kano på Mølleåen. Hele 30 km skal tilbagelægges til søs, til bens og med overnatning i den smukke Nordsjællandske natur. Turen starter ved Mølleåens udspring ved Bastrup sø i Allerød, fortsætter videres til Furesø, ind i Lyngby sø, for til sidst at ende i Øresund.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 6006, 
category: 'Comedy,Underholdning,Livstil', 
sortId: '53,108', 
title: 'Villum & Schmidt - og de 7 små bjerge', 
tagLine: 'Jonas Schmidt laver rekordforsøg', 
programDescriptionShort: '', 
programDescriptionLong: 'Tomas Villum og Jonas Schmidt er tilbage. Denne gang med et rekordforsøg. De to rejsekammerater har sat sig for at bestige de 7 højeste bjerge i Danmark - på under et døgn! Fra Himmelbjerget i Jylland til Rytterknægten på Bornholm. Kom med på en underholdende ekspedition i et af verdens fladeste lande.' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 6007, 
category: 'Comedy,Livstil', 
sortId: '54', 
title: 'Villum & Schmidt i Danmark', 
tagLine: 'Turen Går Til Danmark', 
programDescriptionShort: 'Endnu engang er de to gamle venner Tomas Villum & Jonas Schmidt på rejse sammen. Denne gang går turen rundt i Danmark', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 6008, 
category: 'Comedy,Livstil', 
sortId: '55', 
title: 'Villum & Schmidt på vinrejse', 
tagLine: 'Rejsekammeraterne drager mod Frankrig', 
programDescriptionShort: 'De to kammerater; Tomas Villum og Jonas Schmidt, skal endnu engang på rejseeventyr. Denne gang skal de på vin-tur til Frankrig, hvor de kendte vinområder; Alsace, Bourgogne og Provence, venter', 
programDescriptionLong: 'De to kammerater; Tomas Villum og Jonas Schmidt, skal endnu engang på rejseeventyr. Denne gang skal de på vin-tur til Frankrig, hvor de kendte vinområder; Alsace, Bourgogne og Provence, venter. Det er blevet til tre underholdende tv-programmer omkring høst og produktion af hvidvin, rødvin og rosé.' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 7001, 
category: 'Kultur', 
sortId: '11', 
title: 'Kunsten at trykke', 
tagLine: 'Reportageserie om dansk trykkekunst', 
programDescriptionShort: 'Dokumentar dansk trykkekunst samt kunstnere og trykkere’s samarbejde igennem de tre teknikker: Litografi, Træsnit og Kobbertryk. Få et unikt indblik i kunsten bag hver metode.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 7002, 
category: 'Kultur,Rørende fortællinger,Portræt', 
sortId: '10', 
title: 'Patricia Piccinini - En kærlig verden', 
tagLine: 'Kom tættere på kunstneren Patricia Piccinini', 
programDescriptionShort: 'Produceret af ARKEN i forbindelse med udstillingen \'En Kærlig Verden\' med Patricia Piccinini\'s værker', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 7003, 
category: 'Kultur', 
sortId: '', 
title: 'Carl-Henning Pedersen - den spontane maler', 
tagLine: 'En af Danmarks største malere', 
programDescriptionShort: '"Vær spontan, så alt hvad der er i dig, kommer frem i billedet.\r\nForstår du at handle ud fra fantasiens skabende evne - forstår du også, at kunst ikke kan skabes gennem bevidste konstruktioner.\r\nVil du ha\' det levende frem, som er i dig, må du skabe ud fra dine inderste bevæggrunde"\r\n\r\nSådan lyder et citat fra en af Danmarkshistoriens dygtigste og mest anerkendte malere Carl-Henning Pedersen.\r\n\r\nHer kan du se dokumentarfilmen "Carl-Henning Pedersen - den spontane maler". Filmen er lavet i samarbejde med DR. Produceret af Casablanca Film 2012.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 7004, 
category: 'Kultur', 
sortId: '7', 
title: 'Peter Land – selvportræt som en hjemløs', 
tagLine: 'Peter Land om værket \'Tilbage til start\'', 
programDescriptionShort: 'I en surrealistisk stor cirkel af papkasser ligger en hyperrealistiske udgave af kunstneren Peter Land som hjemløs.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 7005, 
category: 'Kultur', 
sortId: '6', 
title: 'HustMitNavn om sine værker på Høng Gymnasium', 
tagLine: 'HuskMitNavn fortæller sin historie', 
programDescriptionShort: '', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 7006, 
category: 'Kultur,Portræt', 
sortId: '2', 
title: 'Klangfrø - Et portræt af Kirstine Roepstorff', 
tagLine: 0, 
programDescriptionShort: '', 
programDescriptionLong: 'Billedkunstneren Kirstine Roepstorff (f. 1972) er kendt for sine farvestrålende samfundskritiske collager, men har i de seneste år arbejdet med en fascination af vibrationer, lyd og bevægelse. En kunstneriskudvikling, der måske kan reflekteres i kunstnerens private liv.\r\n\r\nI dette portræt kommer vi tæt på Roepstorffs kreative såvel som personlige udvikling og overvejelser, når hun kaster sig ud i nye og eksperimenterende projekter. Udstillingen i Den Danske Pavillon i Venedig udformes for øjnene af os, og vi tages med bag de rørende tanker og det store arbejde ved fremstillingen af værket ’Gongen’ til DOKK1. Men ikke nok med Roepstorffs kunstneriske udfoldelser. Vi får også et unikt indblik i Roepstorffs personlige overvejelser, der resulterer i at flytte fra pulserende Berlin og tilbage til familiehuset, Damgaard, mellem træerne i Danmark.' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 7007, 
category: 'Kultur', 
sortId: '3', 
title: 'Portræt af Christian Lemmerz', 
tagLine: 0, 
programDescriptionShort: '', 
programDescriptionLong: 'Den dansk/tyske kunstner Christian Lemmerz (f. 1959) har opnået den anerkendelse i kunstens verden, som de fleste kun kan drømme om. Hans kunst spænder over mange forskellige genre såsom tegning, skulptur og performance, men ofte med de samme dystre gennemgående temaer og eksistentielle betragtninger. Christian Lemmerz har udstillet verden over og modtaget flere medaljer, men alligevel kæmper kunstneren med selvværdet og spørgsmålet om hvorvidt hans værker rammer tilskueren eller bare står tomme uden indhold.\r\nI dette portræt følger vi Lemmerz i den kunstneriske proces gennem udformningen af den 3,5 meter høje bronzestatue ’Lazarus’. Vi tager med Christian Lemmerz til den italienske kunstnerby Pietra Santa, hvor han arbejder og lever, og hvor hans kreative evner får rum til at udfolde sig.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 7008, 
category: 'Kultur', 
sortId: '4', 
title: 'Den ansvarlige utopist', 
tagLine: 'Portræt af Martin Erik Andersen', 
programDescriptionShort: 'Undersøgende portræt af den prisvindende kunstner og professor ved Kunstakademiet, Martin Erik Andersen', 
programDescriptionLong: 'I dette undersøgende portræt af den prisvindende kunstner og professor ved Kunstakademiet får man som seer mulighed for at trænge ind bag værkerne og få et indblik i kunstnerens proces og en fornemmelse for Martin Erik Andersens budskab. Konstruktionerne skal vise sig at byde på mange lag: mildhed såvel som brutalitet, orden såvel som kaos samt væsentlige samfundskritiske beskeder. For Martin Erik Andersen er god kunst det, som forholder sig til sin samtid; det som stiller spørgsmål frem for at forære svar.\r\n\r\nÅr: 2014\r\nLængde: 28:44 minutter\r\nProduktionsselskab: JJ film\r\nSprog: Dansk' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 7009, 
category: 'Kultur', 
sortId: '5', 
title: 'Øjet, Hullet, Himlen', 
tagLine: 'Et portræt af Kathrine Ærtebjerg', 
programDescriptionShort: '', 
programDescriptionLong: 'Billedkunstneren Kathrine Ærtebjerg sigter efter at ramme det spontane, det poetiske, det følelsesmæssige og det skæve, når hun skaber uden at lade sig selv reflektere alt for meget. Hendes malerier besidder både en barnlig lethed og et mørke, der afspejler en kvindes erfaringer.\r\nI dette portræt af billedkunstneren kommer vi tæt på hendes arbejde både i atelieret og i arbejdet med en udsmykning på trappen i Brandts. Værkerne skabes for øjnene af os, når Kathrine Ærtebjerg på lærredet fremmaner væsener fra sin egen fantasiverden. Vi hører om inspiration fra andre kunstnere, om krop og køn, og om hvorfra lyset og mørket i værkerne stammer.\r\n\r\nÅr: 2011\r\nLængde: 28 minutter\r\nProducer: Jacob Jørgensen\r\nSprog: Dansk' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 7010, 
category: 'Kultur', 
sortId: '8', 
title: 'Portræt af Julie Nord', 
tagLine: 0, 
programDescriptionShort: 'Kunstneren Julie Nord arbejder med temaer som vanetænkning og klicheer, børneværelser og tegneserier.', 
programDescriptionLong: 'Julie Nord arbejder med temaer som vanetænkning og klicheer, børneværelser og tegneserier. Hun laver sirligt udførte tegninger og blander eventyr og popkultur med inspiration fra gyserfilm og psykedelisk kunst.' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 7011, 
category: 'Kultur', 
sortId: '9', 
title: 'Teatermuseet i Hofteatret', 
tagLine: 'En film om Teatermuseet i Hofteatret', 
programDescriptionShort: 'Den internationalt anerkendte videokunstner Julie Born Schwartzs film er en poetisk og stemningsfuld billedfortælling om det mere end 250 år gamle Hofteater, hvis interiører gemmer på så mange historier', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 3012, 
category: 'Underholdning', 
sortId: '90', 
title: 'Gorm og 100% din ret', 
tagLine: 'TV-kokken tager rundt i DK for at lære de unge at lave mad', 
programDescriptionShort: '', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 3013, 
category: 'Underholdning,Unge', 
sortId: '2', 
title: 'Audi Mobility Challeng', 
tagLine: 'FCK-spillere testes udenfor banen', 
programDescriptionShort: 'I tre episoder tester FCK TV og Audi, hvordan spillerne begår sig, når det går ekstra stærkt, når der er knap så meget plads, og når det går voldsomt opad!', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 3014, 
category: 'Underholdning,Unge', 
sortId: '4', 
title: 'Elektrisk', 
tagLine: 'Brohave tester fede biler', 
programDescriptionShort: '', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 8001, 
category: 'StandUp,Unge', 
sortId: '1', 
title: 'Hva\' Sker Der Dansker - med Adam & Noah', 
tagLine: 'Se Adam & Noahs første show', 
programDescriptionShort: '', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 8002, 
category: 'StandUp,Unge', 
sortId: '2', 
title: 'Klogskab - med Adam & Noah', 
tagLine: 'Se Adam & Noahs andet show', 
programDescriptionShort: '', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 8003, 
category: 'StandUp', 
sortId: '3', 
title: 'Grine eller Græde', 
tagLine: 'Dansk onemanshow med Anders Stjernholm', 
programDescriptionShort: '', 
programDescriptionLong: 'Grine eller Græde handler om følelsen af ambivalens, der rammer så ofte: Hørte jeg rigtigt? Skete det virkelig? Danser min kæreste sådan?\r\nReagerer du med latter eller tårer? Er du i tvivl? Det er Anders. Den ene tragikomiske hjerneprut efterfølger den anden, når han ser sig omkring. Det er ryggraden i Anders’ stand-up og i dette show samler han de bedste bidder fra de sidste 10 år' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 8004, 
category: 'StandUp', 
sortId: '4', 
title: 'Gør Sin Ting', 
tagLine: 'Dansk onemanshow med Morten Wichmann', 
programDescriptionShort: 'Nu kommer Morten Wichmanns stand-up show, der er spækket med tempofyldt comedy, syrede observationer og lange jokes om katte', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 8005, 
category: 'StandUp', 
sortId: '5', 
title: 'Ulige uger - onemanshow med Jacob Tingleff', 
tagLine: 'Et stand-up show om livet efter skilsmissen', 
programDescriptionShort: '', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 8006, 
category: 'StandUp', 
sortId: '6', 
title: 'Heino what you did last summer', 
tagLine: '100% improviseret standupshow', 
programDescriptionShort: '', 
programDescriptionLong: '100% improviseret standupshow. Konceptet: Heino har et formiddagsprogram på den lokale radiostation, men han keder sig helt vildt med gamle lyttere og sponserede quizzer. Derfor opfinder han programmet "Heino what you did last summer" - og publikum I salen er programmets lyttere.100% improviseret standupshow. Konceptet: Heino har et formiddagsprogram på den lokale radiostation, men han keder sig helt vildt med gamle lyttere og sponserede quizzer. Derfor opfinder han programmet "Heino what you did last summer" - og publikum I salen er programmets lyttere.\r\n\r\nMedspiller: Palle Birch (dobbelt danmarksmester i impro-comedy)' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 8007, 
category: 'StandUp', 
sortId: '7', 
title: 'Heino & Jakobs oneman-show', 
tagLine: 'To vanvittige komiker. Ét vanvittigt show', 
programDescriptionShort: 'Den skøre radiovært Heino Hansen og den kristne komiker Jakob Svendsen, har skabt et show om showet, der ikke blev til noget. Eller gjorde det? Det er præcis lige så vanvittigt, som det lyder.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 8008, 
category: 'StandUp', 
sortId: '8', 
title: 'Kristen i Krise', 
tagLine: 'Dansk onemanshow med Jakob Svendsen', 
programDescriptionShort: '', 
programDescriptionLong: 'Komikeren Jakob Svendsens personlige fortælling om opvæksten og livet som kristen. Det er fortællingen om en enkelt mands oplevelser i et land som kalder sig kristent. Det er et show hvor Jakob tager os med ind i tro og tvivl, succes og fiasko i kampen for at være et godt menneske. Gud, mirakler, Djævlen og Noah bliver blandet med (mangel på) charme og serveret på et fad af selvironi og hverdagshistorie' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 8009, 
category: 'StandUp', 
sortId: '9', 
title: 'Næste(n)kærlig', 
tagLine: 'Onemanshow med Jakob Svendsen', 
programDescriptionShort: 'Showet, der skulle gøre verden til et bedre sted', 
programDescriptionLong: 'Jakob Svendsen om sit show: Jeg satte mig for at lave et onemanshow, hvor jeg ville gøre verden til et bedre sted.\r\nMen mens jeg lavede showet fandt jeg ud af, at jeg måske slet ikke var så rummelig og næstekærlig som jeg havde troet. Jeg fandt ud af, at det at gøre noget godt for andre ikke altid er ligetil, og at mit eget hoved kunne snyde mig i processen.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 8010, 
category: 'StandUp,Unge', 
sortId: '10', 
title: 'HVID SKYLD', 
tagLine: 'Onemanshow med Mikkel Klint Thorius', 
programDescriptionShort: 'Mikkel har skyldfølelse over at være så privilegeret, som han er. Hvordan kan han leve med at vokse op i trygge Danmark, når verden udenfor er ved at gå i stykker?', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 8011, 
category: 'StandUp', 
sortId: '11', 
title: 'Pyt med skjorten', 
tagLine: 'Onemanshow med Jacob Tingleff', 
programDescriptionShort: 'Pyt med skjorten er Jacobs første onemanshow. Det blev optaget i 2006 og gik langt fra som planlagt.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 8012, 
category: 'StandUp', 
sortId: '12', 
title: 'Flad Næseryg', 
tagLine: 'Onemanshow med Tjelle Vejrup', 
programDescriptionShort: 'Tjelle er 32 år gammel, nybagt far, og så er han så småt begyndt at elske sig selv', 
programDescriptionLong: 'Ja okay, han er i hvert fald i gang med at lære at leve med de små skønhedsfejl, han alligevel ikke kan gøre noget ved. FLAD NÆSERYG handler om noget så super-kliché som at blive voksen, men det er også en rejse til vanvittigt spændende steder som optikeren, fødestuen, Facebooks kommentarspor, den østjyske provins og Bulgarien. Heldigvis hedder guiden Tjelle Vejrup, og han har trods alt otte års erfaring med den slags, så han er vel ikke helt ringe.' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 8013, 
category: 'StandUp', 
sortId: '13', 
title: 'JOKES', 
tagLine: 'Onemanshow med Nikolaj Wulff', 
programDescriptionShort: 'JOKES er Nikolaj Wulffs første show', 
programDescriptionLong: 'Det handler om at tro på sig selv, forfølge drengedrømmen og skrive klichéfyldte pressetekster til sit eget show. JOKES indeholder dumsjove ordspil, en intens historie gennem hele landet og comedyens verden, samt en gennemgang af alle stand-up shows nogensinde.\r\nShowet blev optaget d. 22.05.18 på et udsolgt Bremen Teater.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 8014, 
category: 'StandUp', 
sortId: '14', 
title: 'Wulff & Venner', 
tagLine: 'Nikolaj Wulff i collaboration med venner', 
programDescriptionShort: 'Komiker Nikolaj Wulff præsenterer 6 af sine bedste venner fra den danske stand-up undergrund.', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 8015, 
category: 'StandUp', 
sortId: '15', 
title: 'Krig, Terror og Andre Sjove Ting', 
tagLine: 'Onemanshow med Omar Marzouk', 
programDescriptionShort: 'Omar Mazouk – den halve egypter og hele komiker tager krig og terror så alvorligt, at man bliver nødt til at grine af det', 
programDescriptionLong: 'Omar Mazouk – den halve egypter og hele komiker – er for første gang klar med et one man comedy show, der tager krig og terror så alvorligt, at man bliver nødt til at grine af det.\r\n\r\nKomikeren Omar Mazouk tager bladet fra munden og kaster sig ud i vaskeægte Jihad komik med sit show ”Krig, terror og andre sjove ting”, hvor han går efter at dø på scenen – i håb om at modtage 72 jomfruer efter showet. Men er jomfruer i virkeligheden ikke overvurderet?\r\n\r\nI sommer optrådte Omar for fulde huse på Fringe Festivalen i Edinburgh, verdens største comedy festival. Showet blev modtaget med begejstring hos både publikum og anmeldere fra flere engelske aviser.\r\n\r\nShowet kørte for fulde huse i Danmark i foråret 2005 og mere end 15.000 publikummer så det ca. 90 minutter lange show, som blev foreviget til DVD i Det Ny Teater. Det er kontroversielt og ustyrlig morsomt, og der er masser af ekstramateriale.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 8016, 
category: 'StandUp', 
sortId: '16', 
title: 'Mandehader?', 
tagLine: 'Onemanshow med Sanne Søndergaard', 
programDescriptionShort: 'Sanne Søndergaard elsker mænd, men hun hader dem også. For hvorfor skal de skal have det nemmere, fordi de tilfældigvis er født med en pik?', 
programDescriptionLong: 'Sanne Søndergaard elsker mænd, men hun hader dem også. For hvorfor skal de skal have det nemmere, fordi de tilfældigvis er født med en pik? \r\nDe tror tilmed, at pikken er en gud og brokker sig, hvis kvinder ikke synes det. Men det gør kvinder jo. Pikken ER gud; men derfor vil vi sgu stadig have det samme i løn!' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 8017, 
category: 'StandUp', 
sortId: '17', 
title: 'Sindssyg Kælling', 
tagLine: 'Onemanshow med Sanne Søndergaard', 
programDescriptionShort: 'Sindssyg Kælling er et personligt, sårbart og hudløst ærligt show om at blive stemplet af hele verden, blot fordi man siger sin mening', 
programDescriptionLong: 'Sanne Søndergaard har lavet ét af sine til dato mest hudløse shows, hvor hun går i kødet på både medierne, publikum og sågar sin egen omgangskreds og spidder de mange fordømmende røster, hun har mødt gennem tiden som en kritisk og åbenmundet satiriker.' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 8018, 
category: 'StandUp', 
sortId: '18', 
title: 'Jagten på Løkken', 
tagLine: 'Onemanshow med Mads Holm', 
programDescriptionShort: 'Et show om problematikken med at komme videre fra en depression. Showet blev nævnt som en af de primære grunde til at Mads i 2019 vandt prisen som ‘Årets Talent’ ved Zulu Comedy Galla', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 8019, 
category: 'StandUp,Unge', 
sortId: '19', 
title: 'En Time På Huset', 
tagLine: 'Onemanshow med Martin Nørgaard', 
programDescriptionShort: 'Én Time På Huset" er et stand-up show af og med Martin Nørgaard. Det blev optaget på Huset i Magstræde under Zulu Comedy Festival 2013', 
programDescriptionLong: '' }, 
{ previewImg: 'billboard2c', 
programCollectionId: 8020, 
category: 'StandUp', 
sortId: '20', 
title: 'Hvem Fanden Er Mads de Krak', 
tagLine: 'Onemanshow med Mads de Krak', 
programDescriptionShort: 'StandUp-komikeren Mads de Kraks første onemanshow', 
programDescriptionLong: 'i 2017 gik han i semifinalen ved DM I Stand Up, og hans klip blev efterfølgende set mere end 500.000 gange på YouTube.\r\nI 2018 fik han en finaleplads ved samme konkurrence,\r\nog lavede et onemanshow, som du nu er faldet over.' }, 
{ previewImg: 'billboard1c', 
programCollectionId: 8021, 
category: 'StandUp', 
sortId: '90', 
title: 'Pædagog På Afveje', 
tagLine: 'Onemanshow med Steen Molzen', 
programDescriptionShort: 'Stand-up om at tackle omverden med upædagogisk pædagogik', 
programDescriptionLong: 'Steen er uddannet pædagog, men den indre gadedreng har også et ord at skulle have sagt, når Molzen tackler sin omverden med brug af eksempler, som alle kan nikke genkendende til og lære af, hvis man samtidig er klar til tage konsekvenserne.' }, ]




// [
//     { 
//         previewImg: billboard2c, //c3, //billboard2b,
//         programCollectionId: 1,
//         category: ["Comedy"],
//         title: "WTF gør vi med klimaet",
//         tagLine: "Millang tager temperaturen på klimaet",
//         programDescriptionShort: "Er klimaet virkeligt så presset, som medierne påstår? Står vi virkelig over for en global krise, hvis ikke vi omlægger vores vaner?",
//         programDescriptionLong: "Hvor slemt er det egentlig med klimaforandringerne i Danmark? Vi har læst klimarapporten for dig. I videoen er Magnus Millang på en mission for at finde ud af, hvor slemt det står til med klimaet i Danmark, og hvordan vi kan redde verden.",
//         backgroundPreviewImg: "linear-gradient(97deg, rgba(208,208,208, 1) 5%, rgba(144,144,144, 1) 67%)",
//         textAlign: "right",
//         spanRight: "15%",
//         spanLeft: null,
//         focus: false,
//         // background: "#eea29a",
//     }, 
//     // {
//     //     previewImg: c4, //billboard3,
//     //     videoUrl: "https://www.facebook.com/Fastland/videos/1920713031531794/",
//     //     programCollectionId: 2,
//     //     focus: false,
//     //     category: ["Comedy"],
//     //     title: "bla",
//     //     program: "Jonatan Spang",
//     //     // background: "#eea29a",
//     // },
//     { 
//     previewImg: billboard1c, 
//     programCollectionId: 2,
//     category: ["Comedy", "love"],
//     title: "Celebrate",
//     tagLine: "Zobel har samtaler med stærke kvinder",
//     programDescriptionShort: "Hvad er mod? Hvornår er man en succes? Hvad gør dig lykkelig? Følg med i vores nye interviewserie ”CELEBRATE”, hvor Sarah Zobel søger svarene hos nogle af Danmarks mest inspirerende kvinder.",
//     programDescriptionLong: "I denne nye interview serie ‘Celebrate’ hylder Magasin og Sarah Zobel nogle af Danmarks sejeste kvinder. Det gør vi i Magasins intime prøverum, netop for at kunne komme tæt på disse kvinders historier. I Celebrate vil vi berører mange forskellige emner i disse kvinders liv: succes, sårbarhed, mod, og udfordinger på vejen. Det essentielle er, at vi kan lade os inspirerer af disse passionerede kvinder, ved at være nysgerrige på deres rejse, deres livsvalg og måde at gå til livet på. For fremtiden er feminin. Og det kan disse kvinder i høj grad bekræfte med deres livsbekræftende drive.",
//     backgroundPreviewImg: "linear-gradient(-120deg, rgba(253, 205, 59, 1) 42%, rgba(255, 174, 0, 1) 70%)",
//     textAlign: "left",
//     spanRight: null,
//     spanLeft: "15%",
//     focus: false,
//     },
// ]