export const categoryVideos = 
[
    {
        id: 1,
        category: "Comedy",
        videoUrl: "https://www.youtube.com/watch?v=IOD7vqVlWp4", //"https://player.vimeo.com/video/460306307" //https://www.youtube.com/embed/o_LrZ4eKmLE",
    },
    {
        id: 2,
        category: "Dokumentar",
        videoUrl: "https://player.vimeo.com/video/461088441",
    },
    {
        id: 3,
        category: "Historie",
        videoUrl: "https://player.vimeo.com/video/461088669",
    },
    {
        id: 4,
        category: "StandUp",
        videoUrl: "https://player.vimeo.com/video/461114237",
    },
    {
        id: 5,
        category: "Fiktion",
        videoUrl: "https://player.vimeo.com/video/461113299",
    },
    {
        id: 6,
        category: "Kultur",
        videoUrl: "https://player.vimeo.com/video/461114096",
    },
    {
        id: 7,
        category: "Sportsdokumentarer",
        videoUrl: "https://player.vimeo.com/video/461113718",
    },
    {
        id: 8,
        category: "Underholdning",
        videoUrl: "https://player.vimeo.com/video/461112315",
    },
]