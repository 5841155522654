import React, { useRef, useCallback } from 'react';
import '../src/search.css';
import { pure } from 'recompose';
import {   
    //BrowserRouter as Router,
    Link } from "react-router-dom";
// import { useHistory } from "react-router-dom";

const Search = pure(props => {

	// let { path, url } = useRouteMatch();

	const searchFieldRef = useRef(null);

	const accordionContent = [];

	// function onSearchIconClicked(e) {
	// 	props.handleSearchIconClicked(e)
	// 	props.searchActive && focus()
	// }
	const SearchSuggestion = pure(props => {
		return (
			<Link to={`/${props.value.toLowerCase().replace(/ /g,"_")}`}
				style={{ textDecoration: 'none' }}
			>
				<div className={`search-suggestion accordionContent
				${props.searchTextScroll === props.value && props.searchSuggestionShowAll === false && "search-suggestion--highligted"}
				${(props.searchTextScroll !== props.value || props.searchSuggestionShowAll) && "search-suggestion--default"}
				${props.value  === "_Dummy_" && "search-suggestion--dummy"}
				`}
				onClick={(e) => props.value !== "" && props.value !== "_Dummy_" && props.onCategoryTagClicked(e, props.value)}
				ref={accordionContent[props.key] = accordionContent} 
				// key={props.key}
				>
					<span className={`search-suggestion-span`}
					>
						{props.value}
					</span>
				</div>
			</Link>
		);
	  }, 
	  
		// (prevProps, nextProps) => {
		// 	console.log("chjeck")
		// 	if (prevProps.searchTextPartialMatches === nextProps.searchTextPartialMatches) {
		// 	return true; // props are equal
		// 	}
		// 	return false; // props are not equal -> update the component)
		// }
	)

	
	// function areEqual (prevProps, nextProps) {
	// 	console.log("hej")
	// 	if (prevProps.searchTextPartialMatches === nextProps.searchTextPartialMatches) {
	// 	return true;// props are equal
	// 	}
	// 	return false // props are not equal -> update the component)
	//   } 

	const SearchTextPartialMatches = useCallback(props => {
	const searchTextPartialMatches = ["_Dummy_"].concat(props.searchTextPartialMatches)
	//const searchSuggestionActive = searchTextPartialMatches.length > 1 && props.currentCategory === "" && true

	const searchSuggestions = searchTextPartialMatches.length > 1 ? searchTextPartialMatches.filter(suggestion => suggestion !== "").map((suggestion, i) =>
	<SearchSuggestion 
		key={suggestion+i} 
		index={i} 
		value={suggestion} 
		searchTextPartialMatches={props.searchTextPartialMatches}
		searchTextScroll={props.searchTextScroll}
		searchSuggestionShowAll={props.searchSuggestionShowAll}
		// searchSuggestionClosestIndex={props.searchSuggestionClosestIndex}
		onCategoryTagClicked={() => props.onCategoryTagClicked(suggestion)}
	/>
	)
	: null;
	return (
		<div className={`search-suggestions-container
		${props.xxx ? "" : ""}`}
		onScroll={(e) => props.onSearchTextPartialMatchScroll(e)}
		>	
			<div className={`search-suggestion-container
				${props.xxx ? "" : ""}`}
			>
				{searchSuggestions}
			</div>	
		</div>	
	);
}, [] //dependency was: props.searchTextPartialMatches. Deleted for now due to console info.
// areEqual
	// (prevProps, nextProps) => {
	// 	console.log("hej")
	// 	if (prevProps.searchTextPartialMatches === nextProps.searchTextPartialMatches) {
	// 	return true;// props are equal
	// 	}
	// 	return false // props are not equal -> update the component)
	// }
)

	const RecommendationBy = pure(props => {

		return (
			<>
			<div className={`recommendation
		
				`}>
				<img className={`recommendation-img 

				`}
						src={props.recommender.profileImg}
						alt={""}
						onClick={() => props.onRecommenderClicked(props.recommender)}
				></img>
				
				<div className={`recommendation-img-focus-outer
		
				`}>
					<div className={`recommendation-img-focus-middle
				
					`}>
						<div className={`recommendation-img-focus-inner
			
						`}>
						</div>
					</div>
				</div>

				<div className={`recommendation-name

				`}>
						<span className={`recommendation-name-span
					
							`}>
								{props.recommender.firstName}
						</span>
				</div>

			</div>

			</>
		)
	})

	const RecommendationsBy = useCallback(props => {
		const recommendationsAuthorsList = props.recommendationsAuthorsList
		const recommendationsAuthors = recommendationsAuthorsList.map(recommender =>
		<RecommendationBy
			key={recommender.individualId}
			// value={recommender.firstName} 
			// programCollectionId={recommender.programCollectionId}
			// recommenderId={recommender.individualId}
			onRecommenderClicked={props.onRecommenderClicked}
			recommender={recommender}
		></RecommendationBy>
		)

		const recommendationsShow = (props.searchActive === false && props.gallery === "categories") || (props.searchActive && props.searchText.includes("anbefalet"))

		return (
			<div className={`recommendations-container`}>
				<div className={`recommendations-wrapper`}>
					<div className={`recommendations
					${recommendationsShow ? "recommendations-show" : "recommendations-hide"}
					${props.galleryOverlap ? "recommendations-slideout" : "recommendations-slidein"}
					`}>
						{recommendationsAuthors}
					</div>
					<div className={`recommendations-fade-left-container`}>	
						<div className={`recommendations-fade-left`}>	
						</div>
					</div>
				</div>
			</div>
		)
	}, [])

  function searchFieldFocus() {
	searchFieldRef.current.focus();
  }

  const showSearch = (props.currentCategory !== "" && props.programImagesLoaded) || props.currentCategory === ""
  const placeHolderText = props.currentCategory !== "" ? " i " + props.currentCategory.substring(0, 1).toUpperCase() + props.currentCategory.substring(1).toLowerCase()  : ""
  const searchTextCapitalized = props.searchText.replace(/\b\w/g, l => l.toUpperCase())
  const searchTextScrollCapitilized = props.searchTextScroll.replace(/\b\w/g, l => l.toUpperCase())
;
	return ( 
		<>
		{!props.videoChosen &&
		<div className={`search-container 
			// {!props.videoChosen && "search-container-show"}
			// {props.currentCategory !== "" && props.programImagesLoaded === false && "search-container-hide" || props.videoChosen && "search-container-hide"}
			//{props.gallery === "category" && props.searchText === "" && "search-container-hide" || props.videoChosen && "search-container-hide"}
		`}>
			<div className={`search 
				${props.xxx ? "" : ""}`}>
					<div className={`search-field
					${showSearch ? "search-container-show" : "search-container-hide"}
					`}
					>
						<i className={`fas fa-search
							${props.searchActive ? "fa-search-active" : "fa-search-deactivated"}
							`}
							onClick={
								props.searchActive ? 
								(e) => (props.onSearchIconClicked(e)) :
								(e) => (props.onSearchIconClicked(e), searchFieldFocus())
							}
						></i>
						<form className="form-control"
							onSubmit={(e) => props.onSearch(e)}
						>
							{/* {props.searchActive && */}
							<input 
								className={`input-control
								${props.searchActive ? "input-control-active" : "input-control-deactivated"}`}
								ref={searchFieldRef} 
								type={"text"} 
								value={props.searchTextScroll !== '' ? searchTextScrollCapitilized: searchTextCapitalized} //props.searchText.substring(0, 1).toUpperCase() + props.searchText.substring(1).toLowerCase()}
								placeholder={props.searchActive ? "Søg" + placeHolderText : ""}
								aria-label="Search"
								onChange={(e) => props.onSearchInputChanged(e)}
								// onChange={() => }
							></input>
							{/* } */}
						</form>
					</div>
			</div>

			<SearchTextPartialMatches
				searchTextPartialMatches={props.searchTextPartialMatches}
				onCategoryTagClicked={props.onCategoryTagClicked}
				onSearchTextPartialMatchScroll={props.onSearchTextPartialMatchScroll}
				searchTextScroll={props.searchTextScroll}
				searchSuggestionShowAll={props.searchSuggestionShowAll}
				// searchSuggestionClosestIndex={props.searchSuggestionClosestIndex}
			/>	

			{/* <RecommendationsBy
				onRecommenderClicked={props.onRecommenderClicked}
				galleryOverlap={props.galleryOverlap}
				searchText={props.searchText}
				searchActive={props.searchActive}
				recommendationsAuthorsList={props.recommendationsAuthorsList}
				gallery={props.gallery}
			>

			</RecommendationsBy> */}
		</div>
		}
		</>
	)
	
},
	// (prevProps, nextProps) => {
	// if (prevProps.searchTextPartialMatches === nextProps.searchTextPartialMatches) {
	// return true;// props are equal
	// }
	// return false // props are not equal -> update the component)
	// }
)
        
export default Search;