import React from 'react';
import CategoryGallery from './CategoryGallery';
import ProgramGallery from './ProgramGallery';
import '../src/gallery.css';


const Gallery = (props) => {

    // const programGallerySlideUp = props.gallery === "category" && props.videoChosen === false && props.searchText === "" && props.programImagesLoaded

    return (
      <div className={`gallery-container 
        ${props.galleryContainerShow ? "show" : "hide"}
        ${props.gallery === "category" && "darkmode"}
        ${props.videoChosen && "gallery-container-nopadding"}
      `}
        // style={{background: props.mainGalleryElementHoverId != null && props.categoryGalleryData[props.mainGalleryElementHoverId].background}}
      >
      {/* <div className={`gallery ${props.galleryContainerShow ? "show" : "hide"}`}>
        {categoriesGallery}
        {categoryGalleryData}
      </div> */}
        <div className={`gallery-grid gallery-grid-category-gallery
          ${props.gallery === "categories" ? "gallery-grid--show" : "gallery-grid--hide"}
        `}> 
          <CategoryGallery
            galleryContainerShow={props.galleryContainerShow}
            galleryOverlap={props.galleryOverlap}
            galleryOverlapMore={props.galleryOverlapMore}
            categoryGalleryData={props.categoryGalleryData}
            gallery={props.gallery}
            videoChosen={props.videoChosen}
            categoryImagesLoaded={props.categoryImagesLoaded}
            onGalleryElementSelected={props.onGalleryElementSelected}
            onGalleryElementLeave={props.onGalleryElementLeave}
            onGalleryElementEntered={props.onGalleryElementEntered}
            onCategoryImageChange={props.onCategoryImageChange}
            mainGalleryElementHoverId={props.mainGalleryElementHoverId}
          ></CategoryGallery>
        </div>
        {/* {props.gallery === "category" && */}
        <div className={`gallery-grid gallery-grid-program-gallery
          ${props.gallery === "category" ? "gallery-grid--show" : "gallery-grid--hide"}
        `}> 
          {/* <ProgramGallery
            currentPrograms={props.currentPrograms}
            galleryContainerShow={props.galleryContainerShow}
            categoryGalleryData={props.categoryGalleryData}
            mainGalleryItemsInRow={props.mainGalleryItemsInRow}
            mainGalleryLastElementInRow={props.mainGalleryLastElementInRow}
            mainGalleryELementSelectedId={props.mainGalleryELementSelectedid}
            gallery={props.gallery}
            programs={props.programs}
            videoCollection={props.videoCollection}
            // galleryOverlap={props.galleryOverlap}
            // galleryOverlapMore={props.galleryOverlapMore}
            randomProgramActive={props.randomProgramActive}
            mainGalleryElementHoverId={props.mainGalleryElementHoverId}
            hoverFirstElementInRow={props.hoverFirstElementInRow}
            activeVideoUrl={props.activeVideoUrl}
            activeVideoUrlIndex={props.activeVideoUrlIndex}
            activeTaglineIndex={props.activeTaglineIndex}
            videoChosen={props.videoChosen}
            elementHoverOn={props.elementHoverOn}
            videoStartedId={props.videoStartedId}
            programQueryText={props.programQueryText}
            currentCategory={props.currentCategory}
            activeTitle={props.activeTitle}
            activeSeason={props.activeSeason}
            activeEpisode={props.activeEpisode}
            activeCategory={props.activeCategory}
            activeEpisodeTitle={props.activeEpisodeTitle}
            activeEpisodeDescription={props.activeEpisodeDescription}
            videoDescriptionsShow={props.videoDescriptionsShow}
            activeProgramDescriptionLong={props.activeProgramDescriptionLong}
            searchText={props.searchText}
            audioOff={props.audioOff}
            noOfProgramsInRow={props.noOfProgramsInRow}
            recommendationsList={props.recommendationsList}
            currentRecommenderId={props.currentRecommenderId}
            onVideoStart={props.onVideoStart}
            programImagesLoaded={props.programImagesLoaded}
            onGalleryElementSelected={props.onGalleryElementSelected}
            onGalleryElementLeave={props.onGalleryElementLeave}
            onElementHover={props.onElementHover}
            pipActivated={props.pipActivated}
            // onElementLeave={onElementHover}
            onGalleryElementEntered={props.onGalleryElementEntered}
            onProgramClick={props.onProgramClick}
            onElementTaglineHover={props.onElementTaglineHover}
            onVideoDescriptionsToogleClicked={props.onVideoDescriptionsToogleClicked}
            onElementTaglineLeave={props.onElementTaglineLeave}
            onProgramImageChange={props.onProgramImageChange}
            onVideoEnded={props.onVideoEnded}
          ></ProgramGallery> */}
        </div>
        {/* } */}
      </div>
    )
  }


export default Gallery;