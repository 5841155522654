import React from 'react';
import flowplay from '../src/images/Flowplay-hvid-sort_r.png';
import '../src/header.css';

const Header = (props) => {
    return ( 
        <div className={`header-container 
          ${props.topOfPage ? "top" : "nottop"}
          ${props.videoChosen ? "header-container-hide" : "header-container-show"}
        `}>
          {/* <div className="header"> */}
            {/* <span>FLOWPLAY</span> */}
            {/* <img className={`header-logo 
              ${props.videoChosen ? "header-logo-hide" : "header-logo-show"}
        `    }
              src={flowplay}
              alt={""}
              onClick={() => props.onGoHome()}
            ></img> */}
          <div className={`controls-container
        
          `}>
          {/* <span className={"text-size2"}><i class="fas fa-phone"></i>61 70 20 60</span> */}
          <span className={`text-size4
            ${props.topOfPage ? "top-phone" : "nottop-phone"}
            `}>
         <i class="fas fa-phone"></i><a href="tel:+4561702060">61 70 20 60</a></span>

          </div>
            {/* <span className="hungry">Hungry Demo</span> */}
          {/* </div> */}
        </div>
    )
}
        
export default Header;