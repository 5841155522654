import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import '../src/program-gallery.css';
//import Reactpip from 'react-picture-in-picture'
import {   
    //BrowserRouter as Router,
    Link } from "react-router-dom";

class ProgramGallery extends Component {

    constructor(props) {
      super(props);
      // this.handleImageChange = handleImageChange.bind(this)
      this.state = { 
        // imageLoadCounter: 0,
        // programImagesLoaded: false,
        // categoryImagesLoaded: false,
        // redirect: false,
        // categoryUrl: null,
      }
    }
  
    handleRouting = (e) => {
      // const mainGalleryELementSelectedId = Math.round(e.target.id)
      // const programQueryText = this.props.categoryGalleryData[mainGalleryELementSelectedId-1].category
      // this.setState({redirect: true, categoryUrl: programQueryText});
    }
  
    // handleImageChange = () => {
    //   this.setState({imageLoadCounter: this.state.imageLoadCounter + 1})
    //   (this.state.imageLoadCounter)
    //   let noOfPrograms = this.props.programs.filter(program => program.category.includes(this.props.programQueryText)).length
    //   if (noOfPrograms === this.state.imageLoadCounter) {
    //     console.log("laoded", noOfPrograms)
    //   }
    // };

    
  
    render() {
  
      // if (this.state.redirect) {
      //   const categoryUrl = "/" + this.state.categoryUrl
      //   return <Redirect push to={`${categoryUrl}`} />;
      // }
  
      // let programImageLoadCounter = 0
      // let noOfPrograms = this.props.programs.filter(program => program.category.includes(this.props.programQueryText)).length
      // this.handleProgramImageChange = () => {
      //   programImageLoadCounter = programImageLoadCounter + 1
      //   if (noOfPrograms === programImageLoadCounter) {
      //     this.setState({programImagesLoaded: true});
      //     programImageLoadCounter = 0
      //   }
      // }
  
      // let categoryImageLoadCounter = 0
      // let noOfCategories = this.props.categoryGalleryData.length
      //  this.handleCategoryImageChange = () => {
      //   categoryImageLoadCounter = categoryImageLoadCounter + 1
      //   if (noOfCategories === categoryImageLoadCounter) {
      //     this.setState({categoryImagesLoaded: true});
      //     categoryImageLoadCounter = 0
      //   }
      // }

      //const programGallerySlideUp = this.props.gallery === "category" && this.props.videoChosen === false && this.props.searchText === "" && this.props.programImagesLoaded
      // const recommendedProgramCollectionIds = this.props.currentRecommenderId !== 0 ? this.props.recommendationsList.filter(recommendation => recommendation.individualId === this.props.currentRecommenderId).map(recommendation => recommendation.programCollectionId) : 0
      // const recommendedPrograms = this.props.currentRecommenderId !== 0 ? this.props.programs.filter(program => recommendedProgramCollectionIds.includes(program.programCollectionId)) : null
      
      // const matchAllPrograms =  this.props.programs.filter(program => program.category.some(category => category.toLowerCase().includes(this.props.programQueryText)))
      // // const programQueryText = this.props.programQueryText === "" :
      // const matchAllProgramsInCategory = this.props.searchText === "" ? 
      //   this.props.programs.filter(program => program.category.some(category => category.toLowerCase() === this.props.currentCategory)) : 
      //   this.props.programs.filter(program => program.category.some(category => category.toLowerCase() === this.props.currentCategory)).filter(program => program.category.some(category => category.toLowerCase().includes(this.props.searchText)))
      
      // const currentPrograms = this.props.currentCategory !== "" ? matchAllProgramsInCategory : matchAllPrograms
      
      // const programCollectionIds = this.props.programs.map(program => program.programCollectionId)
      // const randomProgramCollectionId = programCollectionIds[Math.floor(Math.random() * programCollectionIds.length)];
      // const randomProgramSuggestion = this.props.programs.filter(program => program.programCollectionId === randomProgramCollectionId)
      
      // const currentPrograms = []
      // switch (true) {
      //   case this.props.currentCategory !== "":

      //     break;
      //   default:
      // } 
      const currentPrograms = this.props.currentPrograms
      //const categoryGalleryCollection =  currentPrograms
  
        // this.props.programs.filter(program => program.category.some(category => category.includes(this.props.programQueryText))) :
        // this.props.programs.filter(program => program.programCollectionId === 2)
  
      const categoryGalleryData = currentPrograms.map((program, i) => 
          <div className={`element-wrapper
            ${Math.ceil((i+1)/5)*5 === this.props.hoverFirstElementInRow && "scaledown"}
            ${this.props.activeVideoUrlIndex !== i && this.props.videoChosen && "element-wrapper-video-chosen-not"}
            ${this.props.activeVideoUrlIndex === i && this.props.videoChosen && "element-wrapper-video-chosen"}
            ${this.props.activeVideoUrlIndex === i && this.props.videoChosen === false &&  "element-wrapper-hover"}
            ${this.props.activeVideoUrlIndex !== i && this.props.videoChosen && "element-wrapper-chosen-not"}
            //{currentPrograms.length === 0 && this.props.activeVideoUrlIndex !== i && "element-wrapper-focus"}
          `}
            key={program.programCollectionId}
            onMouseLeave={(e) => this.props.elementHoverOn && this.props.onElementTaglineHover(e, i)}
          >
            
            <div className={`element-container
              ${this.props.activeVideoUrlIndex === i && this.props.videoChosen && "element-container-noborder"}
            `}
              // style={{textAlign: program.textAlign}}
              style={{textAlign: "left"}}
            >
            {/* <>
              <div className={"element-left"}
              ></div>
              <div className={"element-right"}></div>
            </> */}
              {this.props.videoChosen === false && 
                <>
                  <span className={`element-container-title-span
                    ${this.props.activeVideoUrlIndex === i && "element-container-title-span-hide"} 
                    ${this.props.activeVideoUrlIndex === null && this.props.activeTaglineIndex === i && "element-container-title-span-hide"}
                  `}
                    style={{
                     // left: program.spanLeft, 
                      //right: program.spanRight, 
                      //color: program.backgroundPreviewImg.substring(program.backgroundPreviewImg.indexOf("rgba"), program.backgroundPreviewImg.indexOf(")"))
                    }}
                  >
                    {program.tagLine !== 0 ? program.tagLine : null} 
                    {/* {<br></br>}
                    {program.category.toString()}
                    {<br></br>}
                    {program.sortId.toString()} */}
                  </span>
                  <div className={`element-container-tagline
                    ${this.props.activeVideoUrlIndex !== null && "element-container-tagline-fade"} 
                    ${this.props.activeTaglineIndex === i && "element-container-tagline-nopointerx"}
                  `}
                    onClick={(e) => this.props.elementHoverOn && this.props.onElementTaglineHover(e, i)}
                    //onMouseLeave={(e) => this.props.elementHoverOn && this.props.onElementTaglineHover(e, i)}
              
                  >
                    <span className={`element-container-tagline-span
                      ${this.props.activeVideoUrlIndex === i && "element-container-tagline-span-hide" }
                    `}
                      // style={{left: program.spanLeft, right: program.spanRight}}
                    > 
                      {program.title !== 0 ? program.title : null}           
                      {program.programDescriptionShort !== "" ? 
                        <i 
                          className={`fa fa-info
                            ${this.props.activeVideoUrlIndex === null && this.props.activeTaglineIndex === i && "fa fa-info--focus"}
                          `}
                          aria-hidden="true"
                          style={{
                          //color: program.backgroundPreviewImg.substring(program.backgroundPreviewImg.indexOf("rgba"), program.backgroundPreviewImg.indexOf(")"))
                          }}
                        ></i> : null}
                    </span>
                  </div>
  
                  <div className={`element-container-information
                    ${(this.props.activeVideoUrlIndex === null && this.props.activeTaglineIndex === i) ? "element-container-information-show" : "element-container-information-hide"}
                  `}
                    // onClick={(e) => this.props.onElementClick(e, i)}
                    >
                    <span className={"element-container-information-span"}
                      // style={{left: program.spanLeft, right: program.spanRight}}
                      onClick={(e) => this.props.elementHoverOn && this.props.onElementTaglineHover(e, i)}
                    >
                      {program.programDescriptionShort}
                    </span>
                  </div>
                  <div className={`element-container-information-fade-top-container

                    `}
                  >	
                    <div className={`element-container-information-fade-top
                        ${(this.props.activeVideoUrlIndex === null && this.props.activeTaglineIndex === i) ? "element-container-information-show" : "element-container-information-hide"}
                    `}
                      //style={{background: `linear-gradient(${program.backgroundPreviewImg.split(";")[0]},${program.backgroundPreviewImg.split(";")[1]}${program.backgroundPreviewImg.split(";")[2]},${program.backgroundPreviewImg.split(";")[3]}${program.backgroundPreviewImg.split(";")[4]})`}}
                     // style={{background: program.backgroundPreviewImg.substring(program.backgroundPreviewImg.indexOf("rgba"), program.backgroundPreviewImg.indexOf(")"))}}
                        style={{background: `linear-gradient(${program.backgroundPreviewImg.split(";")[3]}20%,transparent 95%`}}
                    >	
                    </div>
                  </div>
                </> 
              }
              <div className={`element-background
                ${this.props.activeVideoUrlIndex === i ? "element-background-hover" :"element-background-nothover"}
              `}
                style={{background: `linear-gradient(${program.backgroundPreviewImg.split(";")[0]},${program.backgroundPreviewImg.split(";")[1]}${program.backgroundPreviewImg.split(";")[2]},${program.backgroundPreviewImg.split(";")[3]}${program.backgroundPreviewImg.split(";")[4]})`}}
                // style={{background: program.backgroundPreviewImg}}
                // style={{background: this.props.mainGalleryElementHoverId !== null && this.props.categoryGalleryData[this.props.mainGalleryElementHoverId].background}}
                >
              </div>
        
              {this.props.activeVideoUrlIndex === i && this.props.videoStartedId === null &&
                <div className={`loading-wrapper loading-wrapper--left
                //{program.spanLeft !== null && "loading-wrapper--left"}
                //{program.spanRight !== null && "loading-wrapper--right"}
                `}
                >
                  <div className="preloader">
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                  </div>
                </div>
              }
            <Link to={`/${encodeURI(program.title.toLowerCase().replace(/ /g,"_"))}/1-1`} //program.title.normalize('NFKD').replace(/[\u0300-\u036F]/g, '').toLowerCase().replace(/ /g,"_").replace(/%/g,"")}/1-1`} //}/1-1`}
            //to={`/${encodeURIComponent(program.title)}/1-1`}
            style={{ textDecoration: 'none' }}
            >
              <img className={`element-image 
                ${this.props.activeVideoUrlIndex !== null && "preview-img-fade"}
                //{this.props.activeVideoUrlIndex === i && "preview-img-fadeout"}
                ${this.props.videoStartedId === this.props.activeVideoUrlIndex && this.props.activeVideoUrlIndex === i && "preview-img-fadeout"}
                ${this.props.activeTaglineIndex === i && "preview-img-fademore"}
                ${this.props.videoChosen && "preview-img-nopointerevents"}
                ${this.props.activeVideoUrlIndex === i && "preview-img-hovered"}
                ${program.spanLeft !== null ? "preview-img-origin-right" : "preview-img-origin-left"}
                `}
                  key={program.programCollectionId}
                  src={program.previewImgUrl}
                  //videoUrl={program.videoUrl}
                  id={program.id}
                  alt={program.category}
                  // style={{background: program.backgroundPreviewImg}}
                  onClick={() => this.props.onProgramClick(program.programCollectionId, 1, 1, i)} // i was passed, but dont seems nesseary ... //this.props.onProgramClick(e, i, program)} //program.programCollectionId, program.title, program.season, program.episode, program.episodeTitle, program.category, program.episodeDescription, program.programDescription
                  onMouseEnter={(e) => this.props.activeTaglineIndex !== i && this.props.elementHoverOn && this.props.onElementHover(e, i, program.programCollectionId)}
                  onMouseOver={(e) => this.props.activeTaglineIndex !== i && this.props.elementHoverOn && this.props.onElementHover(e, i, program.programCollectionId)}
                  onMouseLeave={(e) => this.props.activeTaglineIndex !== i && this.props.elementHoverOn && this.props.onElementHover(e, i, program.programCollectionId)}
                  onLoad={() => this.props.onProgramImageChange()}
                  onError={() => this.props.onProgramImageChange()}
                  // onError={this.handleImageChange}
                  // onClick={(e) => this.props.onGalleryElementEnter(e)}
              ></img>
            </Link>
              <div className={`player-wrapper
                ${this.props.videoStartedId === this.props.activeVideoUrlIndex ? "player-fadein" : "player-fadeout"}`}>
                {this.props.activeVideoUrlIndex !== null && this.props.activeVideoUrlIndex === i &&
                  // <Reactpip
                  //   isActive={true}>
                
                  <ReactPlayer
                    className='react-player'
                    url={this.props.activeVideoUrl}
                    width='100%'
                    height='100%'
                    light={false}
                    // playIcon={kvadratisk}
                    playing={true}
                    muted={this.props.audioOff ? true : false} //{this.props.videoChosen ? false : true}
                    // onStart={() => props.onPlayStart()}
                    // volume={0 to 1}
                    pip={this.props.pipActivated ? true : false}
                    onStart={(e) => this.props.onVideoStart(e, i)}
                    // onEnded={() => props.onMediaEnded()}
                    controls={true} //{this.props.videoChosen ? true : false}
                    //onProgress={(loaded) => console.log(loaded)}
                    //onDuration={(loaded) => console.log(loaded)}
                    onEnded={() => this.props.onVideoEnded()}
                  />
                  // </Reactpip>
                }
              </div>
            </div> 
  
            {this.props.videoChosen && this.props.activeVideoUrlIndex === i &&
              <>
              {/* <div className={`element-container-video-category`}>
                  
              </div> */}
                <div className={`element-container-video-information`}>
                {/* {this.props.activeCategory.map((category) => 
                <span className={"tags"}>{category}</span>
                )} */}
                {/* <span className={"title"}>{this.props.activeTitle}</span> */}
                {/* <span className={"season-episode"}>{"S" + this.props.activeSeason + ":E" + this.props.activeEpisode}</span> */}
                {/* <span className={"episode-title"}>{this.props.activeEpisodeTitle}</span>
                <span className={"episode-description"}>{this.props.activeProgramDescription}</span>
                <span className={"episode-description"}>{this.props.activeEpisodeDescription}</span> */}
                
                {/* <div className='row'>
                  <div className='column'>
                    <div className='blue-column'>
                    <span className={"title"}>{this.props.activeTitle}</span>
                    <span className={"episode-description"}>{this.props.activeProgramDescription}</span>
                    </div>
                  </div>
                  <div className='column'>
                    <div className='green-column'>
                    <span className={"episode-title"}>{this.props.activeEpisodeTitle}</span>
                    <span className={"episode-description"}>{this.props.activeEpisodeDescription}</span>
                    </div>
                  </div>
                </div> */}
  
                  <div className="grid-container">
                    <div className="grid-item">
                      <span className={"title"}>{this.props.activeTitle +":"}</span>
                          {/* <i className="fa fa-align-left" aria-hidden="true"></i> */}
                          <span className={"episode-title"}>{this.props.activeEpisodeTitle}</span>
                      <i className="fas fa-stream" aria-hidden="true"
                        onClick={() => this.props.onVideoDescriptionsToogleClicked()}
                      ></i>
                    </div>
                    {/* <div class="grid-item">
                    </div> */}
                    <div className="grid-item">
                      <div className={`video-descriptions
                        ${this.props.videoDescriptionsShow ? "video-descriptions-show" :"video-descriptions-hide"}
                      `}>
                        <span className={"episode-description"}>{this.props.activeProgramDescriptionLong}</span>
                        <span className={"episode-description"}>{this.props.activeEpisodeDescription}</span>
                      </div>
                    </div>  
                    {/* <div class="grid-item">
                     
                    </div> */}
                  </div>
                </div>
              </>
            }
           
        </div> 
          /* i % this.props.mainGalleryElementsInRow === 0 not working? */
          /* {i%4 === 0 ? 
            <>
              <div className={"ghostelement"}></div>
              <div className={`break`}></div>
            </> 
          : null} */
          /* {i === this.props.mainGalleryLastElementInRow - 1 ? <div className={`gallery-sub`}>{subGallery}</div> : null} */
        
      )
  
      const noOfCurrentPrograms = this.props.programs.filter(program => program.category.some(category => category.includes(this.props.programQueryText))).length
      const noOfGhostElements = this.props.noOfProgramsInRow - (noOfCurrentPrograms % this.props.noOfProgramsInRow)
      
      const ghostElements =  Array(noOfGhostElements).fill().map((i, index) => 
        <div className={`element-wrapper
          ${Math.ceil((i+1)/5)*5 === this.props.hoverFirstElementInRow && "scaledown"}
          ${this.props.activeVideoUrlIndex !== i && this.props.videoChosen && "element-wrapper-video-chosen-not"}
          ${this.props.activeVideoUrlIndex === i && this.props.videoChosen && "element-wrapper-video-chosen"}
          ${this.props.activeVideoUrlIndex === i && this.props.videoChosen === false &&  "element-wrapper-hover"}
          ${this.props.activeVideoUrlIndex !== i && this.props.videoChosen && "element-wrapper-chosen-not"}
        `}
        key={index}
        >
        </div>
      )
  
      const programSuggestionInfo = ["Din søgning gav 0 resultater.", "Søg igen.", "Eller måske frister dette program?"]
      const programSuggestionInfoElements = Array(2).fill().map((i, index) => 
        <div className={`element-wrapper
          ${Math.ceil((i+1)/5)*5 === this.props.hoverFirstElementInRow && "scaledown"}
          ${this.props.activeVideoUrlIndex !== i && this.props.videoChosen && "element-wrapper-video-chosen-not"}
          ${this.props.activeVideoUrlIndex === i && this.props.videoChosen && "element-wrapper-video-chosen"}
          ${this.props.activeVideoUrlIndex === i && this.props.videoChosen === false &&  "element-wrapper-hover"}
          ${this.props.activeVideoUrlIndex !== i && this.props.videoChosen && "element-wrapper-chosen-not"}
        `}>
          {index === 1 &&
          <div className={`element-suggestion-info`}>
            {programSuggestionInfo.map((info, i) => 
              <span className={`element-suggestion-info-span`}
                key={i}
              > 
                {info}
              </span>
            )}
          </div>
          }
        </div>
      )
      
      // const programGallerySlideUp = this.props.gallery === "category" && this.props.videoChosen === false && this.props.searchText === "" && this.props.programImagesLoaded
      //const programGallerySlideUp = (this.props.categoryGalleryData.filter(categoryGallery => categoryGallery.category === this.props.programQueryText).length > 0 && this.props.programImagesLoaded) || this.props.videoChosen //this.props.gallery === "category" && this.props.videoChosen === false && this.props.searchText === "" && this.props.programImagesLoaded
      const programGallerySlideUp = 
        (this.props.currentCategory === "" && this.props.searchText !== "") ||
        (this.props.currentCategory !== "" && this.props.programImagesLoaded) || 
        (this.props.currentCategory !== "" && this.props.searchText !== "") || 
        this.props.videoChosen //this.props.gallery === "category" && this.props.videoChosen === false && this.props.searchText === "" && this.props.programImagesLoaded
  

      const programGalleryShow = this.props.gallery === "category" || this.props.searchText !== ""
      return (
        <>
    {/* //included to mitigate that randomProgramSuggestion is updated after load of page (before categoryh is chosen), and fires onLoad and counter of images, so its become wrong. More robust implementation probablty needed */}
        <div className={`gallery program-gallery
          ${programGallerySlideUp ? "program-gallery--slideup" : "program-gallery--low"}
          ${programGalleryShow ? "program-gallery--show" : "program-gallery--hide"}
          ${this.props.randomProgramActive ? "program-gallery--align-top" : "program-gallery--align-center"} 
          ${this.props.videoChosen ? "translate-more gallery--left" : "gallery--centered"} 
          ${this.props.galleryContainerShow ? "show" : "hide"}
          ${this.props.videoChosen ? "gallery-fullwidth" : "gallery-narrow"}
          //{this.props.gallery === "category" ? "category-gallery-show" : ""}` //
            }>
              {this.props.randomProgramActive && programSuggestionInfoElements}
              {categoryGalleryData}
              {ghostElements}
        </div>
       {/* } */}
        </>
      )
    }
  }
  
  export default ProgramGallery;