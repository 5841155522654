import floradanica_0170 from '../src/images/floradanica_0170.png';
import floradanica_0788 from '../src/images/floradanica_0788.png';
import floradanica_1107 from '../src/images/floradanica_1107.png';
import floradanica_1110 from '../src/images/floradanica_1110.png';
import floradanica_1306 from '../src/images/floradanica_1306.png';
import floradanica_1374 from '../src/images/floradanica_1374.png';
import floradanica_1826 from '../src/images/floradanica_1826.png';
import floradanica_2055 from '../src/images/floradanica_2055.png';

export const categoryGalleryData = 
[
    { 
      // image: pic1,
      image: floradanica_0170,
      id: 1,
      focus: false,
      category: "Project & Program Management",
      description: ["Lean Project Management", "Agile Project Management", "Scoping", "Planning", "Execution", "Stakeholder Management", "Risk Management"],
      // background: "rgba(67,102,214,0.5)",
      // backgroundPreviewImg: "linear-gradient(135deg, rgba(0,102,214,1) 0%, rgba(0,102,214,0.2) 50%, rgba(67,102,214,1) 100%)",
      background:  "rgba(61,153,112,0.2)",
      backgroundPreviewImg: "radial-gradient(circle, rgba(67,102,214,0.8) 0%, rgba(67,102,214,0.9) 30%, rgba(67,102,214,1) 50%, rgba(67,102,214,1) 80%)",
    },
    { 
      // image: pic2,
      image: floradanica_0788,
      id: 2,
      focus: false,
      category: "Quality & Compliance Management",
      description: ["QMS Management", "Governance Management", "Change Management", "SOP Management", "Inspection Readiness", "Audit Management", "CAPA Management", "Non-Conformity Handling"],
      // background:  "rgba(61,153,112,0.2)",
      // backgroundPreviewImg: "linear-gradient(135deg, rgba(0,116,217,1) 0%, rgba(127,219,255,1) 50%)", ///backgroundPreviewImg: "linear-gradient(135deg, rgba(61,153,112,1) 0%, rgba(46,204,64,1) 50%)",
      background:  "rgba(61,153,112,0.2)",
      backgroundPreviewImg: "radial-gradient(circle, rgba(67,102,214,0.8) 0%, rgba(67,102,214,0.9) 30%, rgba(67,102,214,1) 50%, rgba(67,102,214,1) 80%)",
    },
    { 
      // image: pic4,
      image: floradanica_1107,
      id: 3,
      focus: false,
      category: "LEAN Consulting",
      description: ["Lean Project Management", "Process Optimisation", "Continous Improvements", "Systematic Problem Solving", "Training"],
      // background: "rgba(255,133,27,0.2)",
      // backgroundPreviewImg: "linear-gradient(135deg, rgba(0,116,217,1) 0%, rgba(127,219,255,1) 50%)", ///backgroundPreviewImg: "linear-gradient(120deg,  transparent 0%, transparent 57%, #423246 100%),linear-gradient(-45deg, #8f5046 0%,  #A14436 0.3%, #4C2556 112%,  transparent 61% ),linear-gradient(-90deg, #A14436 100%, #A14436 65%, transparent 50% )",
      background:  "rgba(61,153,112,0.2)",
      backgroundPreviewImg: "radial-gradient(circle, rgba(67,102,214,0.8) 0%, rgba(67,102,214,0.9) 30%, rgba(67,102,214,1) 50%, rgba(67,102,214,1) 80%)",
    },
    { 
      // image: pic4,19
      image: floradanica_1306,
      id: 4,
      focus: false,
      category: "Programming",
      description: ["ReactJs", "Excel/VBA"],
      // background: "rgba(255,133,27,0.2)",
      // backgroundPreviewImg: "linear-gradient(135deg, rgba(0,116,217,1) 0%, rgba(127,219,255,1) 50%)", ///backgroundPreviewImg: "linear-gradient(110deg, #FF8C00 30%, #ffed4b 80%)", //"linear-gradient(135deg, rgba(255,133,27,1) 0%, rgba(255,220,0,1) 50%)",
      background:  "rgba(61,153,112,0.2)",
      backgroundPreviewImg: "radial-gradient(circle, rgba(67,102,214,0.8) 0%, rgba(67,102,214,0.9) 30%, rgba(67,102,214,1) 50%, rgba(67,102,214,1) 80%)",
    },
    // { 
    //   // image: pic5,
    //   image: floradanica_1110,
    //   id: 5,
    //   focus: false,
    //   category: "Programming",
    //   description: ["ReactJs", "Excel/VBA", "Agile methodology"],
    //   background: "rgba(177,13,201,0.2)",
    //   backgroundPreviewImg: "linear-gradient(135deg, rgba(177,13,201,1) 0%, rgba(240,18,190,1) 50%)",
    // },


    // { 
    //   // image: pic7,
    //   image: floradanica_1374,
    //   id: 6,
    //   focus: false,
    //   category: "Process Design: Risk Assessment and User Requirements",
    //   description: ["Bla bla bla bla bla lorum ipsum hej der"],
    //   background: "rgba(0,116,217,0.2)",
    //   backgroundPreviewImg: "linear-gradient(45deg,  transparent 0%, transparent 57%, #423246 100%),linear-gradient(-45deg, #8f5046 0%,  #A14436 0.3%, #4C2556 112%,  transparent 11% ),linear-gradient(-90deg, #A14436 100%, #A14436 65%, transparent 20% )",
    // },
    // { 
    //   // image: pic7,
    //   image: floradanica_1826,
    //   id: 7,
    //   focus: false,
    //   category: "Fiktion",
    //   description: ["Bla bla bla bla bla lorum ipsum hej der"],
    //   background: "rgba(0,116,217,0.2)",
    //   backgroundPreviewImg: "linear-gradient(45deg,  transparent 0%, transparent 57%, #423246 100%),linear-gradient(-45deg, #8f5046 0%,  #A14436 0.3%, #4C2556 112%,  transparent 11% ),linear-gradient(-90deg, #A14436 100%, #A14436 65%, transparent 20% )",
    // },
    // { 
    //   // image: pic10,'
    //   image: floradanica_2055,
    //   id: 8,
    //   focus: false,
    //   category: "Historie",
    //   description: ["Bla bla bla bla bla lorum ipsum hej der"],
    //   background: "rgba(0,116,217,0.2)",
    //   backgroundPreviewImg: "linear-gradient(70deg, #fff810  30%, rgba(0,0,0,0) 30%), linear-gradient(30deg, #63e89e 60%, #ff7ee3 60%)",
    //},
  ]