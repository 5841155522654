import React from 'react';
import '../src/footer.css';

const Footer = (props) => {

	return ( 
		<>
		{props.gallery === "categories" &&
		<div className={`footer-container 
			${props.xxx ? "" : ""}`}>
			{/* <a href="+45 61 70 20 60"></a> */}
			<a href="tel:+4542929423">mobil 42929423</a>
			{/* <span>+45 61 70 20 60</span> */}

			{/* <a href="mailto:ricobpn@gmail.com">ricobpn@gmail.com</a> */}
			{/* <span>ricobpn@gmail.com</span> */}
			<span>CVR 44724022</span>
		</div>
		}
		</>
	) 
}
        
export default Footer;